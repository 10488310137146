// import process from 'pages/analytics/process/locale/pt_BR'

const localeValues = {
  locale: 'es-es',
  global: {
    error: {
      messages: {
        serverError: 'Error al conectarse al servidor',
        defaultError: 'Hubo un problema. Vuelve a intentarlo.',
        loadDataError: 'Error al cargar los datos, inténtalo de nuevo.',
        equipmentError: 'Error al intentar consultar los datos del equipo',
        reportError: 'Error al intentar consultar los datos del boletín',
        reconciliationError: 'Error al intentar realizar la reconciliación',
        sendError: 'Hubo un problema al enviar datos. Vuelve a intentarlo.',
        deleteError:
          'Hubo un error al intentar eliminar los datos, inténtelo de nuevo.',
        syntaxError: 'Texto sin datos, verifique la sintaxis',
        calculationError: 'Verifique la sintaxis',
        pdfError: 'Hubo un problema al exportar el PDF, vuelve a intentarlo',
        errorImportingFile:
          'Hubo un error en la importación, revise el archivo y vuelva a intentarlo.',
        imageError: 'El archivo insertado no es una imagen.',
        attachmentError:
          'Error al intentar agregar un archivo adjunto, inténtelo de nuevo',
      },
    },
    info: {
      downsamplePoints:
        'Datos con muestreo reducidos. Para consultar todos los puntos disminuya el periodo',
      noPermission: 'Sin permiso',
      dividerFormAddButton: '¡La última parada debería durar más de 1 segundo!',
      diffIndicatorsValues:
        'Configuración del indicador divergente, uso de la configuración del indicador de secuencia 1',
      blankToAutoGenerated: 'Dejar en blanco para generar automáticamente',
      noPermissionForItem: 'No tienes permiso para acceder a este elemento',
      loadingComponents:
        'Hay componentes que se están cargando en esta pestaña o en las otras pestañas.',
      inactiveUser: 'Usuario inactivo',
    },
    success: {
      messages: {
        download: 'Descarga completada con éxito',
        registration: 'Registro exitoso',
        update: 'Cambio realizado con éxito',
        delete: 'Eliminado con éxito',
        save: 'Guardado exitosamente',
        reconciliation: 'Reconciliación exitosa',
        integrated: 'Integración exitosa',
        import: 'Importación realizada con éxito',
        attachment: 'Adjunto agregado exitosamente',
      },
    },
    warning: {
      messages: {
        pointsQuantity:
          'El número de puntos de los Tags es diferente, intente agrupar para realizar el análisis',
        pointsQuantityDifferent: 'El número de puntos de los Tags es diferente',
        pointsInsufficient:
          'Número insuficiente de puntos para realizar el cálculo',
        noAppointments: 'Sin puntos para el día',
        registerTagLimit: 'Se alcanzó el límite de Tags',
        noComponentType: 'Componente sin tipo válido:',
        searchNoGroupBy:
          'Buscar sin agrupamiento, las Tags que no tienen puntos no aparecían para la vista previa.',
      },
    },
    loading: {
      messages: {
        downloading: 'Preparando archivo para descarga...',
        saving: 'Guardando ...',
        loading: 'Cargando...',
        finished: 'Finalizada',
      },
    },
    labels: {
      available: 'Disponible',
      used: 'Usado',
      stock: 'Valores',
      qrCode: 'QR Code',
      operators: 'Operadores',
      createdAt: 'Creado en',
      updatedAt: 'Actualizado en',
      time: 'Tiempo',
      permissions: 'Permisos',
      color: 'Color',
      placeholder: 'Por favor elija',

      code: 'Código',
      codeExists: 'El código ya existe',
      action: 'Acción',
      actions: 'Acciones',
      configuration: 'Configuración',
      exitConfiguration: 'Salir de la configuración',
      deleteConfirmation:
        '¿Está seguro de que desea eliminar el elemento seleccionado?',
      cancelConfirmation: '¿Estas seguro que quieres cancelar?',
      notAllowConfirmation: '¿Estás seguro de que no quieres permitirlo?',
      revisionConfirmation: '¿Estás seguro que quieres crear una nueva reseña?',
      revisionChecklistConfirmation:
        '¡Para continuar, debes completar una nota!',
      typeObservation: 'Ingrese una nota',
      revisionInserted: 'Revisión insertada correctamente',
      revision: 'Revisión',
      notFound: 'Lo sentimos, la página que visitaste no existe',
      yes: 'Si',
      no: 'No',
      changeForm: 'Cambiar formulario',
      registrationForm: 'Formulario de registro',
      close: 'Cerrar',
      lot: 'Lote',
      description: 'Descripción',
      recents: 'Reciente',
      undefined: 'Indefinido',
      dateStart: 'La fecha de inicio',
      dateEnd: 'La fecha de finalización',
      tag: 'Tag',
      tags: 'Tags',
      more: 'Más',
      upload: 'Cargar',
      profile: 'Perfil',
      period: 'Período',
      periods: 'Periodos',
      type: 'Tipo',
      types: 'Tipos',
      group: 'Grupo',
      groups: 'Grupos',
      all: 'Todos',
      message: 'Mensaje',
      date: 'Fecha',
      user: 'Usuario',
      userExists: 'Usuario ya existe',
      users: 'Usuarios',
      userGroups: 'Grupo de Recursos',
      password: 'Contraseña',
      form: 'Formulario',
      notes: 'Notas',
      limit: 'Límite',
      limits: 'Limites',
      value: 'Valor',
      unt: 'Unidad',
      general: 'General',
      generals: 'General',
      name: 'Nombre',
      auto: 'Automático',
      manual: 'Manual',
      atributes: 'Atributos',
      standardDeviation: 'Desviación Estándar',
      average: 'Media',
      variance: 'Varianza',
      better: 'Mejor',
      worst: 'Peor',
      groupBy: 'Agrupar',
      noGroupBy: 'Sin agrupamiento',
      hour: 'Hora',
      shift: 'Turno',
      shiftGrouped: 'Turno - Agrupado',
      day: 'Día',
      week: 'Semana',
      month: 'Mes',
      year: 'Año',
      custom: 'Personalizado',
      vertical: 'Vertical',
      horizontal: 'Horizontal',
      total: 'Total',
      items: 'Elementos',
      editMode: 'Modo de edición',
      chart: 'Gráfico',
      boxplot: 'BoxPlot',
      selectTagsChart: 'Seleccionar Tags para generar el gráfico',
      selectFramesChart: 'Seleccionar Tags para generar el gráfico',
      pValue: 'Valor p',
      quantity: 'Cantidad',
      difference: 'Diferencia',
      upperQuartile: 'Cuartil superior',
      lowerQuartile: 'Cuartil inferior',
      maximum: 'Máximo',
      minimum: 'Mínimo',
      median: 'Mediana',
      sum: 'Soma',
      options: 'Opciones',
      enable: 'Habilitar',
      disable: 'Inhabilitar',
      enablePointsInChart:
        'Habilite el uso de puntos en el gráfico y en los cálculos',
      disablePointsInChart:
        'Deshabilitar el uso del punto en el gráfico y en los cálculos',
      needToBeInEditionMode:
        'Debe ingresar al modo de edición para cambiar el la configuración',
      data: 'Datos',
      motive: 'Motivo',
      motives: 'Motivos',
      accumulated: 'Acumulado',
      event: 'Evento',
      start: 'Inicio',
      end: 'Final',
      status: 'Estado',
      settings: 'Ajustes',
      observation: 'Notas',
      criticality: 'Criticidad',
      equipment: 'Equipo',
      element: 'Elemento',
      requiredField: 'Este campo es requerido',
      text: 'Texto',
      texts: 'Textos',
      outEspecificationLimit: 'Fuera del límite de especificación',
      outValidLimit: 'Fuera del límite válido',
      noData: 'Sin datos',
      withData: 'Con datos',
      system: 'Sistema',
      unit: 'Unidad',
      units: 'Unidades',
      allUnits: 'Todas las unidades',
      allUsers: 'Todos los usuarios',
      allTags: 'Todas las tags',
      allInstruments: 'Todos los instrumentos',
      allElements: 'Todos los elementos',
      list: 'Lista',
      lists: 'Listas',
      details: 'Detalles',
      filter: 'Filtrar',
      inProduction: 'Producción',
      concluded: 'Concluido',
      selectDate: 'Seleccionar una fecha',
      upperLimit: 'Limite superior',
      lowerLimit: 'Límite inferior',
      search: 'Buscar',
      lsc: 'Límite de control superior',
      lc: 'Línea central',
      lic: 'Límite de control inferior',
      hideYAxis: 'Ocultar eje y',
      showYAxis: 'Mostrar eje y',
      showXAxis: 'Mostrar eje x',
      hideValues: 'Ocultar valores',
      showValues: 'Mostrar valores',
      sequence: 'Secuencia',
      line: 'Línea',
      column: 'Columna',
      onlyLessThan500Points: 'Solo cuando menos de 500 puntos',
      min: 'Min',
      max: 'Max',
      amplitude: 'Amplitud',
      select: 'Seleccione',
      closedEvent: 'Evento cerrado',
      openEvent: 'Evento abierto',
      frame: 'Evento',
      frames: 'Eventos',
      information: 'Información',
      conditions: 'Condiciones',
      count: 'Número de puntos',
      durationFrame: 'Duración',
      lastValue: 'Último valor',
      firstValue: 'Primer valor',
      mean: 'Media',
      title: 'Título',
      result: 'Resultado',
      key: 'Llave',
      operator: 'Operador',
      maxValue: 'Valor máximo',
      minValue: 'Valor mínimo',
      centralValue: 'Valor central',
      sumValues: 'Suma de valores',
      tagNotFound: 'Tag no encontrado',
      or: 'O',
      and: 'Y',
      component: 'Componente',
      display: 'Mostrar',
      advanced: 'Avanzado',
      visualization: 'Visualización ',
      fields: 'Campos',
      annul: 'Anular',
      default: 'Estándar',
      automaticAppointment: 'Nota automática',
      tagValue: 'Valor de tag',
      automaticAppointmentTag: 'Tag de autoapunto',
      referenceValue: 'Valor de referencia',
      now: 'Ahora',
      attachments: 'Archivos adjuntos',
      file: 'Expediente',
      noUser: 'Ningún usuario',
      os: 'O.S.',
      height: 'Altura',
      width: 'Ancho',
      displayNoData: 'Mostrar "sin datos"',
      sector: 'Sector',
      confirm: 'Confirmar',
      currentDate: 'Fecha actual',
      item: 'Artículo',
      expandAll: 'Expandir todo',
      minute: 'Minuto',
      bold: 'Audaz',
      italic: 'Itálico',
      underline: 'Subrayada',
      textColor: 'Color',
      separator: 'Separador',
      link: 'Enlace',
      fontSize: 'Tamaño de fuente',
      why: 'Por qué',
      what: 'Qué',
      how: 'Como',
      isActive: 'Activo',
      expiresIn: 'Expira en',
      batch: 'Batch',
      noTemplates:
        'No hay plantillas registradas, el PDF se exportará en la plantilla predeterminada',
      reverse: 'Reembolsar',
      reversed: 'Reembolsado',
      toggleReverse: 'Orden inverso',
      images: 'Imágenes',
    },
    button: {
      settings: 'Ajustes',
      annulPoint: 'Anular Nota',
      enablePoint: 'Habilitar Nota',
      splitPoint: 'Dividir parada',
      historic: 'Histórico',
      delete: 'Eliminar',
      edit: 'Editar',
      process: 'Procesar',
      show: 'Mostrar',
      update: 'Actualizar',
      insert: 'Insertar',
      cancel: 'Cancelar',
      save: 'Guardar',
      saveAndGo: 'Guardar y mostrar',
      comment: 'Comentar',
      change: 'Cambiar',
      backToInitialPage: 'Regresar a la página principal',
      back: 'Regresar',
      insertNew: 'Insertar nuevo',
      registerNew: 'Registrar nuevo',
      enter: 'Iniciar sesión',
      export: 'Exportar',
      import: 'Importar',
      addPeriod: 'Agregar período',
      remove: 'Eliminar',
      add: 'Agregar',
      approve: 'Aprobar',
      notApprove: 'Desaprovado',
      reprove: 'Reprender',
      integrate: 'Integrar',
      reconciliate: 'Reconciliar',
      download: 'Descargar',
      validateKey: 'Validar clave',
      cleanData: 'Borrar datos',
      exclude: 'Eliminar',
      addItem: 'Agregar elemento',
      simulate: 'Simular',
      changeScales: 'Cambiar escalas',
      reset: 'Reiniciar',
      filter: 'Filtrar',
      saveChanges: 'Guardar ediciones',
      ok: 'Okay',
      execute: 'Ejecutar',
      qrCode: 'Código QR',
      addAttachment: 'Añadir un adjunto',
      clearAppointments: 'Eliminar nota',
      allow: 'Permitir',
      notAllow: 'No permitir',
      create: 'Crear',
      language: 'es-ES',
      newRevision: 'Nueva reseña',
      showPdf: 'Ver PDF',
    },
  },
  menu: {
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Análisis de datos',
      submenu: {
        analysisProcess: 'Análisis de Proceso',
        eventManager: 'Gestión de Eventos',
        correlation: 'Correlación',
        operator: 'Análisis de Operador',
        comparativeBetweenPeriods: 'Comparación entre períodos',
        reportPerPeriods: 'Reporte por Períodos',
      },
    },
    dataMining: {
      title: 'Minería de datos',
      submenu: {
        regression: 'Regresión',
        clustering: 'Agrupamiento (Cluster)',
      },
    },
    controlLoopManagement: {
      title: 'Lazos de control',
      submenu: {
        performance: 'Desempeño',
        overview: 'Salud de Lazos de Control',
      },
    },
    statisticalControl: {
      title: 'Control estadístico',
      submenu: {
        exploratory: 'Exploratorio',
        production: 'Producción',
        management: 'Gestión de desviaciones',
      },
    },
    rootCause: {
      title: 'Análisis de causa raíz',
      submenu: {
        actionsAndPlans: 'Causas y planes de acción',
        generateAnalysis: 'Generar análisis',
        actionList: 'Lista de acción',
      },
    },
    equipmentManagement: {
      title: 'Gestión de equipos',
      submenu: {
        stoppingPoint: 'Notas de paradas',
        stoppingChart: 'Gráfico de paradas',
        approveStop: 'Aprobar Paradas',
      },
    },
    opsManagement: {
      title: 'Gestión de OP',
    },
    manufacture: { title: 'Fabricar' },
    operationalSecurity: {
      title: 'Seguridad Operacional',
      submenu: {
        impairments: 'Impairments',
        routineTests: 'Pruebas de Rutina',
      },
    },
    managementOfChanges: {
      title: 'Gestión del cambios',
      submenu: {},
    },
    dataEntry: {
      title: 'Entrada de datos',
      submenu: {
        form: 'Formulario',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Anotaciones',
      },
    },
    export: {
      title: 'Exportación',
      submenu: {
        data: 'Datos',
        event: 'Eventos',
      },
    },
    scale: {
      title: 'Balanza de pesaje',
    },
    operationalAlerts: {
      title: 'Alertas operativas',
    },
    consistency: {
      title: 'Consistencia',
      submenu: {
        productionAndProcess: 'Producción y proceso',
        equipmentStopping: 'Parada del equipo',
      },
    },
    log: {
      title: 'Log',
    },
    integratedMaintenance: {
      title: 'Mantenimiento Integrado',
      submenu: {
        serviceOrderPanel: 'Panel de Orden de Servicio',
        osGenerator: 'Generador de O.S. Preventiva',
        osOverview: 'Descripción del O.S.',
        osHistory: 'Historia del O.S.',
        plannedExecuted: 'Planeado x Ejecutado',
        benchAppointment: 'Cita de Banco',
        reports: 'Informes',
        telemetry: 'Telemetría',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Estructura operativa',
        submenu: {
          barcodeLabel: 'Codigo de Barra',
          parameters: 'Parámetros',
          elementsTree: 'Árbol de Elementos',
          operator: 'Operador',
          shiftsAndTimes: 'Turnos y Horarios',
          pdfTemplate: 'Plantillas PDF',
          token: 'Token',
          globalVariables: 'Variables Globales',
          unitMeasurement: 'Unidad de Medida',
          product: 'Producto',
        },
      },
      tags: {
        title: 'Tags',
        submenu: {
          tags: 'Tags',
          tagsGroup: 'Grupo de Tags',
          goals: 'Metas',
        },
      },
      alerts: {
        title: 'Alertas',
        submenu: {
          alertsGroup: 'Grupo de Alertas',
          alertsConfiguration: 'Configurar alertas',
        },
      },
      manufacture: {
        title: 'Fabricar',
        submenu: {
          defect: 'Defecto',
          status: 'Status',
          variation: 'Variación',
          checklist: 'Lista de verificación',
          checklistItems: 'Elementos de la lista de verificación',
          srcOrder: 'Enrutamiento de orden de producción',
          checklistApprovalMatrix:
            'Matriz de aprobación de la lista de verificación',
        },
      },
      operationalSecurity: {
        title: 'Seguridad Operacional',
        submenu: {
          instrumentSeverity: 'Gravedad',
          instrumentInterlock: 'Enclavamiento',
          instrumentRisk: 'Riesgo',
          instrumentFrequency: 'Frecuencia',
          impairmentRule: 'Normas de Impairment',
          instrumentMitigationMeasures: 'Medidas de Atenuación',
          instrumentPrefix: 'Prefijo',
          instrument: 'Instrumentos',
          userGroupTree: 'Árbol de Grupos de Usuarios',
          approvalMatrix: 'Matriz de Aprobación',
        },
      },
      managementOfChanges: {
        title: 'Gestión del cambios',
        submenu: {
          areaCriticality: 'Criticidad del Área',
          risk: 'Riesgos',
          responsibleArea: 'Área responsable',
          approvalMatrix: 'Matriz de Aprobación',
        },
      },
      eventManager: {
        title: 'Gestión de eventos',
        submenu: {
          frames: 'Eventos',
          motives: 'Motivos',
          complementaryTypes: 'Tipos complementarios',
        },
      },
      controlLoopManagement: {
        title: 'Lazos de control',
      },
      oee: {
        title: 'OEE',
      },
      rootCause: {
        title: 'Análisis de Causa Raíz',
        submenu: {
          causes: 'Causas',
          rules: 'Normas',
        },
      },
      logbookGroup: {
        title: 'Grupo de Anotaciones',
      },
      integration: {
        title: 'Integración',
        submenu: {
          api: 'API',
          system: 'Sistema',
          laboratory: 'Laboratorio',
          integrationParameters: 'Parámetros de integración',
        },
      },
      statisticalControl: {
        title: 'Control estadístico',
        submenu: {
          cards: 'Cartas',
          rules: 'Reglas',
          help: 'Lista de Ayuda',
          diagnosticsList: 'Lista de Diagnósticos',
          correctiveActions: 'Acciones correctivas',
        },
      },
      manualCollector: {
        title: 'Colección manual',
        submenu: {
          collectGroup: 'Grupo de colección',
          collectTags: 'Tags de colección',
        },
      },
      integratedMaintenance: {
        title: 'Mantenimiento Integrado',
        submenu: {
          reportRegistration: 'Informe de Registro',
          inspectionRoutes: 'Rutas de Inspección',
          materialsServices: 'Materiales y Servicios',
          modalities: 'Modalidades',
          frequency: 'Frecuencia',
          status: 'Status',
          maintenanceActivity: 'Actividad de Mantenimiento',
          priority: 'Prioridad',
          serviceOrderTypes: 'Tipos de órdenes de servicio',
          laborRules: 'Reglas Laborales',
          services: 'Servicios',
        },
      },
      accessControl: {
        title: 'Controle de acceso',
        submenu: {
          userRegistration: 'Usuario',
          userGroup: 'Grupo de Recursos',
          accessProfile: 'Perfil de Acceso',
        },
      },
    },
  },
  breadcrumbs: {
    home: 'Inicio',
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Análisis de datos',
      page: {
        analysisProcess: 'Análisis de proceso',
        eventManager: 'Gestión de eventos',
        correlation: 'Correlación',
        operator: 'Operador',
        comparativeBetweenPeriods: 'Comparación entre períodos',
        reportPerPeriods: 'Reporte por Períodos',
      },
    },
    dataMining: {
      title: 'Minería de datos',
      page: {
        regression: 'Regresión',
        clustering: 'Agrupamiento (Cluster)',
      },
    },
    controlLoopManagement: {
      title: 'Lazos de control',
      page: {
        performance: 'Desempeño',
        overview: 'Salud de Lazos de Control',
      },
    },
    statisticalControl: {
      title: 'Control estadístico',
      page: {
        exploratory: 'Exploratorio',
        production: 'Producción',
        management: 'Gestión de desviaciones',
      },
    },
    rootCause: {
      title: 'Análisis de causa raíz',
      page: {
        actionsAndPlans: 'Causas y planes de acción',
        effects: 'Efectos',
        charts: 'Gráficos',
        generateAnalysis: 'Generar análisis',
        actionList: 'Lista de acción',
      },
    },
    equipmentManagement: {
      title: 'Gestión de equipos',
      page: {
        stoppingPoint: 'Notas de paradas',
        stoppingChart: 'Gráfico de paradas',
        approveStop: 'Aprobar Paradas',
      },
    },
    opsManagement: {
      title: 'Gestión de OP',
    },
    manufacture: {
      title: 'Fabricar',
      page: {
        steps: 'Etapas',
      },
    },
    dataEntry: {
      title: 'Entrada de datos',
      page: {
        form: 'Formulario',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Anotaciones',
      },
    },
    export: {
      title: 'Exportación',
      page: {
        data: 'Datos',
        event: 'Eventos',
      },
    },
    scale: {
      title: 'Balanza de pesaje',
    },
    operationalAlerts: {
      title: 'Alertas operativas',
    },
    consistency: {
      title: 'Consistencia',
      page: {
        productionAndProcess: 'Producción y proceso',
        equipmentStopping: 'Parada del equipo',
      },
    },
    log: {
      title: 'Log',
    },
    expired: {
      title: 'Contrato vencido',
    },
    integratedMaintenance: {
      title: 'Mantenimiento Integrado',
      page: {
        serviceOrderPanel: 'Panel de Orden de Servicio',
        osGenerator: 'Generador de O.S. Preventiva',
        osOverview: 'Descripción del O.S.',
        osHistory: 'Historia del O.S.',
        plannedExecuted: 'Planeado x Ejecutado',
        benchAppointment: 'Cita de Banco',
        telemetry: 'Telemetría',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Estructura operativa',
        page: {
          barcodeLabel: 'Codigo de Barra',
          parameters: 'Parámetros',
          elementsTree: 'Árbol de Elementos',
          shiftsAndTimes: 'Turnos y Horarios',
          pdfTemplate: 'Plantillas PDF',
          operator: 'Operador',
          token: 'Token',
          globalVariables: 'Variables Globales',
          unitMeasurement: 'Unidad de Medida',
          product: 'Producto',
        },
      },
      tags: {
        title: 'Tags',
        page: {
          tags: 'Tags',
          tagsGroup: 'Grupo de Tags',
          goals: 'Metas',
        },
      },
      operationalSecurity: {
        title: 'Seguridad operacional',
        page: {
          instrumentSeverity: 'Gravedad',
          instrumentRisk: 'Riesgo',
          instrumentInterlock: 'Enclavamiento',
          instrumentFrequency: 'Frecuencia',
          impairmentRule: 'Normas de impairment',
          instrumentMitigationMeasures: 'Medidas de Atenuación',
          instrumentPrefix: 'Prefijo',
          instrument: 'Instrumentos',
          userGroupTree: 'Árbol de grupos de usuarios',
          approvalMatrix: 'Matriz de aprobación',
        },
      },
      managementOfChanges: {
        title: 'Gestión del cambios',
        page: {
          areaCriticality: 'Criticidad del Área',
          risk: 'Riesgos',
          responsibleArea: 'Área responsable',
          approvalMatrix: 'Matriz de Aprobación',
        },
      },
      alerts: {
        title: 'Alertas',
        page: {
          alertsGroup: 'Grupo de Alertas',
          alertsConfiguration: 'Configurar alertas',
        },
      },
      eventManager: {
        title: 'Gestión de eventos',
        page: {
          frames: 'Eventos',
          motives: 'Motivos',
          complementaryTypes: 'Tipos complementarios',
        },
      },
      controlLoopManagement: {
        title: 'Lazos de control',
      },
      oee: {
        title: 'OEE',
      },
      rootCause: {
        title: 'Análisis de Causa Raíz',
        page: {
          causes: 'Causas',
          rules: 'Normas',
        },
      },
      logbookGroup: {
        title: 'Grupo de Anotaciones',
      },
      integration: {
        title: 'Integración',
        page: {
          api: 'API',
          system: 'Sistema',
          laboratory: 'Laboratorio',
          integrationParameters: 'Parámetros de integración',
          details: 'Detalles',
        },
      },
      statisticalControl: {
        title: 'Control estadístico',
        page: {
          cards: 'Cartas',
          rules: 'Reglas',
          help: 'Lista de Ayuda',
          diagnosticsList: 'Lista de Diagnósticos',
          correctiveActions: 'Acciones correctivas',
        },
      },
      manualCollector: {
        title: 'Colección manual',
        page: {
          collectGroup: 'Grupo de colección',
          collectTags: 'Tags de colección',
        },
      },
      integratedMaintenance: {
        title: 'Mantenimiento Integrado',
        page: {
          reportRegistration: 'Informe de Registro',
          inspectionRoutes: 'Rutas de Inspección',
          materialsServices: 'Materiales y Servicios',
          modalities: 'Modalidades',
          frequency: 'Frecuencia',
          status: 'Status',
          maintenanceActivity: 'Actividad de Mantenimiento',
          priority: 'Prioridad',
          serviceOrderTypes: 'Tipos de órdenes de servicio',
          laborRules: 'Reglas Laborales',
          services: 'Servicios',
        },
      },
      manufacture: {
        title: 'Fabricar',
        page: {
          defect: 'Defecto',
          status: 'Status',
          variation: 'Variación',
          checklist: 'Lista de verificación',
          checklistItems: 'Elementos de la lista de verificación',
          srcOrder: 'Enrutamiento de orden de producción',
          checklistApprovalMatrix:
            'Matriz de aprobación de la lista de verificación',
        },
      },
      accessControl: {
        title: 'Controle de acceso',
        page: {
          userRegistration: 'Usuario',
          userGroup: 'Grupo de Recursos',
          accessProfile: 'Perfil de Acceso',
        },
      },
      unitParameters: {
        title: 'Parámetros de la unidad',
      },
      reports: {
        title: 'Reportes',
      },
    },
  },
  components: {
    shortcuts: {
      title: 'Atajos',
      shortcut: 'Atajo',
      new: 'Nuevo atajo',
      creator: 'Creador',
      moreThanOnePeriod: 'Más de un período seleccionado',
      seeAll: 'Ver todos',
      saved: 'Grabado',
    },
    shareButton: {
      copyLink: 'Copiar link',
      print: 'Imprimir',
      exportPdf: 'Exportar para PDF',
      exportCsv: 'Exportar para CSV',
      exportHorizontalCsv: 'Exportar CSV horizontal',
      share: 'Compartir',
      copied: '¡Enlace copiado!',
      loadingFile: 'Cargando archivo ...',
    },
    dateSelector: {
      today: 'Hoy',
      yesterday: 'Ayer',
      lastWeek: 'Ultima semana',
      lastMonth: 'El mes pasado',
      harvest: 'Año',
      weekSelect: 'Seleccionar semana',
    },
    cacheBuster: {
      newUpdate: '¡Nueva actualización!',
      updateDescription:
        'Hay una nueva actualización disponible, haga clic en "Actualizar" para que el sistema se actualice',
    },
    tagSelect: {
      recents: 'Reciente',
    },
    frameSelect: {
      recents: 'Reciente',
    },
    layout: {
      menu: {
        dashboard: {
          addingDashboard: 'Añadiendo un nuevo dashboard ...',
          noTitle: 'Sin titulo',
          dashboardSuccess: 'Dashboard creado correctamente',
          dashboardError:
            'Hubo un problema al intentar crear el dashboard. Vuelve a intentarlo ...',
          newDashboard: 'Nuevo dashboard',
        },
        buttonConfig: {
          exitConfiguration: 'Salir de la configuración',
          configuration: 'Configuración',
        },
      },
      header: {
        alert: {
          notification: 'Notificaciones',
        },
        dropdown: {
          refeshApply: 'Actualiza la página para aplicarla.',
          refreshNow: 'Actualizar ahora',
          unitSuccess: '¡Datos de la unidad cargados correctamente!',
          translationSuccess: '¡La traducción se cargó correctamente!',
          industrialUnits: 'Unidades industriales',
          languages: 'Idiomas',
          language: 'Idioma',
        },
      },
    },
    richTextInput: {
      small: 'Pequeño',
      normal: 'Normales',
      large: 'Grande',
      huge: 'Enorme',
    },
  },
  expired: {
    title: 'Vencimiento',
    paragragh:
      'El tiempo de su contrato expiró, comuníquese con el administrador del sistema.',
    key: 'Introduzca aquí la clave para renovar el contrato',
    timeToExpire:
      'El contrato vencerá el {count, plural, one {# día} other {# días}}',
    renewTextButton: 'haga clic aquí para renovar',
  },
  statisticalControl: {
    title: 'Control estadístico',
    noTagCep: 'El Tag no se aplica a CEP',
    byPeriod: 'Por período',
    card: 'Carta',
    cards: 'Cartas',
    zones: 'Zonas',
    limits: 'Limites',
    diagnostic: 'Diagnóstico',
    diagnosticConsolidated: 'Diagnóstico consolidado en el período',
    diagnosticList: 'Lista de diagnóstico',
    action: 'Acción',
    actionConsolidated: 'Acción consolidada en el período',
    actionList: 'Lista de Acción',
    yScales: 'Escalas Y',
    pleaseInput: 'Por favor inserte',
    exploratory: {
      imr: 'Individuales',
      ewma: 'EWMA',
      xbarR: 'X-R',
      xbarS: 'X-S',
      lambda: 'Constante de amortiguación',
      k: 'Coeficiente de apertura de los límites de control',
      mean: 'Media objetiva',
      standardDeviation: 'Desviación estándar objetivo',
      standardDeviationWithin: 'Desviación estándar (dentro)',
      standardDeviationOverall: 'Desviación estándar (general)',
      analysis: 'Análisis estadístico del proceso',
      graphType: 'Tipo de gráfico',
      capacityAnalysis: 'Análisis de capacidad de proceso',
      cp: 'CP',
      cpi: 'IPC',
      cps: 'CPS',
      cpk: 'CPK',
      pp: 'PP',
      ppi: 'PPI',
      pps: 'PPS',
      ppk: 'PPK',
      input: 'Entrada',
      outSector: 'Fuera del sector',
      expected: 'Esperado',
      real: 'Real',
      li: 'LI',
      ls: 'LS',
      lic: 'LIC',
      lc: 'LC',
      lsc: 'LSC',
      histogram: 'Histograma',
      normalDistribution: 'Distribución normal',
      quantity: 'Cantidad de Muestra',
      especificationLimits: 'Límites de especificación',
      simulateControlLimits: 'Simular límites de control',
      hidePoints: 'Ocultar puntos',
      pointsAverage: 'Puntos medios',
      startTime: 'Inicio del período analizado',
      endTime: 'Fin del período analizado',
      colectedValue: 'Valor obtenido',
    },
    production: {
      diagnosticItems: 'Elementos de diagnóstico',
      actionItems: 'Elementos de acción correctiva',
      inserted: 'Insertado',
      edited: 'Editado',
      newPoint: 'Nuevo punto',
      outLimit: 'Fuera de los límites',
      autoUpdate: 'Auto-reproducción',
      help: 'Ayuda',
      lastUser: 'Último usuario',
      point: 'Punto',
      finishNote: 'Finalizar nota',
    },
    management: {
      noInformation: 'Sin información del día',
      noIndication: 'No hay indicación',
      showAll: 'Ver todo',
      showPointed: 'Mostrar puntos anotados',
      pareto: 'Pareto',
      percent: 'Porcentaje',
      pie: 'Pizza',
      spc: 'CEP',
      finished: 'Finalizado',
      notFinished: 'No Finalizado',
    },
    rules: {
      title: 'Reglas',
      of: 'de',
      outEspecificationLimit: 'punto fuera de los límites de especificación',
      outControlLimit: 'punto fuera de los límites de control',
      sameSide: 'puntos consecutivos en el mismo lado de la línea central',
      increasingOrDescreasing: 'puntos consecutivos aumentando o disminuyendo',
      aboveOrBelow: 'puntos consecutivos, alternando arriba y abajo',
      twoStandardDeviation:
        'puntos consecutivos superiores a 2 desviaciones estándar de la línea central (mismo lado)',
      oneStandardDeviation:
        'puntos consecutivos superiores a 1 desviación estándar de la línea central (mismo lado)',
      insideOneStandardDeviation:
        'puntos consecutivos dentro de 1 desviación estándar de la línea central (cualquier lado)',
      anySide:
        'puntos consecutivos por encima de una desviación estándar de la línea central (cualquier lado)',
    },
    configuration: {
      card: {
        newRevision: 'Nueva revisión',
        deleteCard:
          '¿Estás seguro de que deseas eliminar la carta? (Todas sus revisiones también serán eliminadas)',
        createCardRevision:
          '¿Está seguro de que desea crear una nueva revisión?',
        rulingToShowTags:
          'Mostrando solo Tags con reglas registradas y que no tienen otra carta',
        cardProject: 'Proyecto de Carta',
        revisionMotive: 'Razón de la revisión',
        scale: 'Escala',
        yAxis: 'Escala y',
        yAxisUnit: 'Unidad de escala Y',
        lower: 'Inferior',
        cannotBeGreaterMax: 'No puede ser más grande que el máximo',
        center: 'Central',
        upper: 'Superior',
        cannotBeLowerMin: 'No puede ser menos que el mínimo',
        lowerEspecification: 'Límite de especificación inferior',
        upperEspecification: 'Límite superior de especificación',
        helpVariable: 'Lista de Ayuda',
        revisionDate: 'Fecha de revisión de la carta',
        revisionPeriod: 'Periodo de validez de esta revisión',
        revisionInserted: 'Revisión insertada correctamente',
        controlLimitsMessage:
          'Si no se ingresan los límites de control, el sistema los calculará automáticamente',
      },
      rules: {
        title: 'Regla',
        ruleZero: "Punto (s) 'K' fuera de los límites de especificación",
        ruleOne: "Punto (s) 'K' fuera de los límites de control",
        ruleTwo:
          "'K' punto (s) consecutivos en el mismo lado de la línea central",
        ruleThree:
          "'K' punto (s) consecutivos, todos crecientes o decrecientes",
        ruleFour: "'K' punto (s) consecutivos, alternando arriba y abajo",
        ruleFive:
          "'K-1' de 'K' puntos consecutivos por encima de 2 desviaciones estándar de la línea central (mismo lado)",
        ruleSix:
          "'K-1' de 'K' puntos consecutivos por encima de 1 desviación estándar de la línea central (mismo lado)",
        ruleSeven:
          "'K' punto (s) consecutivos dentro de una desviación estándar de la línea central (en ambos lados)",
        ruleEight:
          "'K' punto (s) consecutivos por encima de 1 desviación estándar de la línea central (ambos lados)",
        kFactor: 'Factor K',
        tagQuantity: 'Número de Tags',
        rulesQuantity: 'Numero de reglas',
      },
    },
  },
  alert: {
    title: 'Alerta',
    notSeen: 'No visto',
    noCause: 'Sin causa',
    withStaggering: 'Con escalonamiento',
    notificationsSent: 'Notificaciones enviadas',
    seen: 'Visto',
    cause: 'Porque',
    chat: 'Chat',
    notificate: 'Notificar',
    sendNotification: 'Enviar notificación',
    configuration: {
      defaultMessage: 'Mensaje estándar',
      helpChain: 'Cadena de ayuda',
      initialGroups: 'Grupos de inicio',
      lastGroup: 'Último grupo',
      groupNotFound: 'Grupo no encontrado',
      permissions: 'Permisos',
      maxTimeView: 'Tiempo máximo de visualización (minutos)',
      userPermission: 'Permiso para usuarios',
      showPopup: 'Mostrar alerta através de un pop-up',
      redirect: 'Redirigir al notas de parada al ver lo pop-up',
      redirectExtra:
        'Esta opción solo funcionará si se selecciona la opción de mostrar pop-up',
    },
  },
  auth: {
    signin: {
      typeUser: 'Ingrese su nombre de usuario',
      typePassword: 'Escribe tu contraseña',
      failure:
        'El nombre de usuario o la contraseña es incorrecta, verifique sus datos',
      unauthorized: 'El usuario no tiene permiso de acceso a ninguna unidad',
      inactiveUser:
        'Usuario inactivo, póngase en contacto con el administrador',
      withoutContract: 'El usuario no tiene unidades contratadas',
      failurePassword:
        'intentos restantes, verifique sus datos o comuníquese con el administrador',
      failurePasswordLimit:
        'Intentos de inicio de sesión superados, solicite una nueva contraseña al administrador',
      welcomeToIntelup: 'Bienvenido a la plataforma Intelup',
      permissionNotification:
        'Para continuar usando la plataforma, será necesario permitir el almacenamiento de datos como nombre, correo electrónico y foto. Si no lo permite, no podrá utilizar la plataforma.',
      permissionNotificationInformations:
        'El sistema utiliza datos personales como nombre, correo electrónico y foto. Estos datos NO se utilizarán con fines publicitarios y NO se venderán a terceros. El uso es único y exclusivo para el correcto funcionamiento de la plataforma.',
      permissionNotificationQuestion:
        '¿Permitir que la plataforma almacene estos datos?',
    },
    changePassword: {
      title: 'Cambia la contraseña',
      comparePassword: 'Las contraseñas ingresadas son inconsistentes',
      newPasswordNecessity: 'Se requiere cambio de contraseña:',
      newPassword: 'Nueva contraseña',
      typeNewPassword: '¡Ingrese la nueva contraseña!',
      confirmPassword: 'Confirmar nueva contraseña',
      typeConfirmPassword: '¡Ingrese la confirmación para la nueva contraseña!',
    },
    strength: {
      eightCaracters: '8 caracteres',
      oneUppercase: '1 mayúscula',
      oneLowercase: '1 minúscula',
      oneNumber: '1 número',
      oneSpecial: '1 carácter especial',
    },
  },
  controlLoopManagement: {
    title: 'Lazo de control',
    manipulatedVariable: 'Variable manipulada',
    processVariable: 'Variable de proceso',
    setPoint: 'Setpoint',
    performance: {
      title: 'Desempeño',
      mainChart: {
        operationMode: 'Modo de operación',
      },
    },
    tune: {
      title: 'Afinación',
      target: 'Objetivo',
      kp: 'Kp',
      ki: 'Ki',
      kd: 'Kd',
      standardDeviationTarget: 'Objetivo de desviación estándar',
    },
    reliability: {
      title: 'Confiabilidad',
    },
    stats: {
      processAnalysis: 'Análisis estadístico del proceso',
      permanenceTime: 'Duración de la estancia',
      absoluteError: 'Error absoluto medio',
      setPointNumber: 'Número de cruces de Setpoint',
    },
    operationMode: {
      title: 'Modo de operación',
    },
    overview: {
      loopGroup: 'Grupo de Lazos',
      autoTime: 'Tiempo automático',
    },
    configuration: {
      mnemony: 'Mnemonia',
      newGroup: 'Nuevo grupo',
      mnemonyExist: 'Los mnemónicos ya existen',
      autoCreate: 'Crear automáticamente',
      percentage: 'Porcentaje',
      percentuals: {
        automaticManual: 'Porcentajes - AM',
        automaticManualDescription:
          'Rango de los punteros que se muestran en Salud de Mallas de Control - AM (más grande - mejor)',
        standardDeviation: 'Porcentajes - Desviación estándar',
        standardDeviationDescription:
          'Rango de los punteros que se muestran en Salud de Mallas de Control - Desviación estándar (menor - mejor)',
      },
      percentageInfo:
        'Reporte el límite inferior que representará el comienzo del rango del puntero en definición',
      color: 'Color',
      afterCreation: 'Disponible después de la creación del lazo de control',
    },
  },
  dataMining: {
    regression: {
      relatedTags: 'Tags relacionados',
      r2: 'r²',
      r2Adjusted: 'r² ajustado',
      significanceF: 'Significado F',
      coefficients: 'Coeficientes',
      standardError: 'Error estándar',
      matrix: 'Matriz',
      selectTagsMatrix: 'Seleccione tags para generar la matriz',
    },
    clustering: {
      cluster: 'Agregación',
      clusters: 'Agregación',
      yAxis: 'Escala y',
      xAxis: 'escala x',
    },
  },
  equipmentManagement: {
    stoppingChart: {
      sector: 'Sector',
      stopTime: 'Tiempo de parada',
      percentage: 'Por ciento',
      cumulativePercentage: 'Porcentaje acumulado',
      notPointed: 'Sin notas',
      showNotPointed: 'Mostrar sin notas',
      notPlanned: 'Sin planificado',
      planned: 'Planificado',
      quantity: 'Cantidad',
      hours: 'Horas',
      dataToShow: 'Datos que se mostrarán',
      inOperation: 'En operación',
      paretoByMotive: 'Diagrama de Pareto por motivo',
      paretoByGroup: 'Diagrama de Pareto por grupo de motivos',
      paretoByFrame: 'Diagrama de Pareto por evento',
      pizzaByMotive: 'Porcentaje por motivo',
      pizzaByGroup: 'Porcentaje grupo de motivos',
      pizzaByFrame: 'Porcentaje por evento',
      ganttByMotive: 'Línea de tiempo por un motivo',
      ganttByGroup: 'Línea de tiempo por grupo de motivos',
      ganttByFrame: 'Línea de tiempo por un evento',
    },
    stoppingPoint: {
      manualPoint: 'Apuntado manual',
      totalTime: 'Tiempo Total',
      finished: 'Finalizada',
      open: 'Abierto',
      annul: 'Anulada ',
      stillOpen: 'La parada sigue ocurriendo',
      splitStop: 'Parada dividida',
      deleteStop: 'Eliminar parada',
      split: 'Dividir parada',
      serviceOrder: 'Orden de Servicio',
      historic: 'Histórico',
      deletePoint: 'Eliminar Nota',
      updates: 'Actualizaciones',
      notPointed: 'Sin notas',
      pointed: 'Notas',
      showMicroStop: 'Monitor microparada',
      motivesGroup: 'Grupo de motivos',
      annulStoppingConfirmTitle: '¿Quieres anular este artículo?',
      annulStoppingConfirmContent:
        'Cuando cancele esta parada, no se eliminará, pero ya no se contará.',
      enableStoppingConfirmTitle: '¿Quieres habilitar este elemento?',
      enableStoppingConfirmContent:
        'Al habilitar esta parada, se volverá a contar.',
    },
    approveStop: {
      show: 'Monitor',
      approve: 'Aprobar',
      reprove: 'Fallar',
      approved: 'Aprobado',
      reproved: 'Desaprobado',
      historic: 'Histórico',
    },
    configuration: {
      importItems: 'Importar elementos',
      sussesImportingEvent: '¡Evento importado con éxito!',
      relationImportWarning:
        '¡Algunas configuraciones no se encontraron en la planta actual, por lo que no se importaron!',
    },
  },
  consistency: {
    equipmentStop: {
      integratedStatus: 'Estado de integración',
      notApproved: 'No aprobado',
      approved: 'Aprobado',
      integrated: 'Integrado',
      integratedError: 'Error de integración',
      homologation: 'homologación',
      frameDescription: 'Descripción del evento',
      daysReleased: 'Días liberados',
      currentDay: 'Día actual',
      stopsList: 'Lista de paradas',
    },
    productionProcess: {
      releasedBulletin: 'Reporte publicado',
      daysReleased: 'Días liberados',
      currentDay: 'Día actual',
      wantReconciliation: '¿Quieres reconciliarte?',
      tagExternal: 'Tag externo',
      obtainedValues: 'Valores obtenidos',
      limits: 'Límites de especificación',
      substituteValue: 'Valor sustituto',
      justification: 'Justificación',
      changes: 'Cambios',
      textValue: 'Valor de texto',
      numberValue: 'Valor numérico',
      substituteVariableType: 'Tipo de variable sustituta',
      numeric: 'Numérico',
      alphanumericValue: 'Valor alfanumérico',
      approved: 'Aprobado',
      available: 'Proveer',
      formEditAlertMessage:
        'El estado de integración se restablecerá, ¿quieres continuar?',
    },
  },
  log: {
    title: 'Log',
    login: 'Inicio de sesión',
    navigation: 'Navegación',
    integration: 'Integración',
    productionProcess: 'Producción y proceso',
    equipmentStop: 'Parada del equipo',
    dataReconciliation: 'Reconciliación de datos',
    dataEntry: 'Entrada de datos',
    colect: 'Recolección manual',
    manual: 'Manual',
    dateSchedule: 'Fecha / horario',
    consolidation: 'Consolidación',
    consolidatedLog: 'Registro consolidado',
    appointmentStop: 'Parar notas',
    rootCause: 'Análisis de causa raíz',
    equipmentManager: 'Gestión de equipos',
    system: 'Sistema',
    action: 'Acción',
    logged: 'Iniciado/concluído sesión en el sistema',
    approveStop: 'Aprobar parada',
    errors: {
      conversion: 'Conversión error',
      eventManager: 'Administrador de eventos error',
      consolidation: 'Consolidación error',
    },
    actions: {
      execution: {
        conversion: 'Conversión ejecutada',
        eventManager: 'Administrador de eventos ejecutado',
        integrationAPI: 'API de integración ejecutada',
        productionOrder: 'Orden de producción ejecutada',
        productionOrderAutomaticAppointment:
          'Se ha realizado la anotación automática de la orden de producción',
      },
      access: 'Accedido',
      insert: 'Insertado',
      delete: 'Eliminado',
      update: 'Actualizado',
      reversed: 'Invertido',
      login: 'Iniciar sesión',
      logoff: 'Desconectarse',
      loginApp: 'Iniciar sesión - APP',
      logoffApp: 'Desconectarse - APP',
      integrate: 'Integrar',
      reconciliate: 'Reconciliar',
      insertPoint: 'Insertar nota',
      approve: 'Aprobar',
      approved: 'Aprobado',
      reproved: 'Desaprobado',
      insertTag: 'Tag insertada',
      editTag: 'Tag editada',
      deletedTag: 'Tag eliminada',
      tagsDeletedInfo:
        'Fecha del primer punto: {firstDate}, fecha del último punto: {lastDate}, cantidad de puntos: {count}',
      tagsInsertedInfo: 'Valor: {currentValue}, fecha de punto: {pointDate}',
      tagsEditedInfo:
        'Valor anterior: {prevValue}, valor actual: {currentValue}, fecha de punto: {pointDate}',
      insertStepItemNote: 'Insertar anotación (orden de producción)',
      updateStepItemNote: 'Editar nota (orden de producción)',
      deleteStepItemNote: 'Eliminar anotación (orden de producción)',
      reverseStepItemNote: 'Reembolsar anotación (orden de producción)',
    },
  },
  dataEntry: {
    messages: {
      lessEqualThan: 'Ingrese un valor menor o igual que',
      greaterEqualThan: 'Ingrese un valor más grande o igual que',
      outLowerEspecificationLimit:
        'Valor fuera del límite de especificación inferior',
      outUpperEspecificationLimit:
        'Valor fuera del límite de especificación superior',
      outEspecificationLimit: 'Fuera del límite de especificación',
      outValidLimit: 'Fuera del límite válido',
      logbookSuccess: 'Anotación exitosa',
      logbookError: 'Error al intentar anotar',
      logbookEdit: 'Anotación cambiada con éxito',
      logbookDelete: 'Anotación eliminada correctamente',
    },
    csv: {
      dataError: 'El formato de fecha es incorrecto',
      downloadExample: 'Descargar ejemplo',
      selectCsv: 'Seleccione CSV',
    },
    manual: {
      dateAndTime: 'Fecha y hora',
    },
    colect: {
      colectGroupAndTags: 'Colección / Grupo de colección con Tags',
      selectGroup: 'Seleccionar el grupo',
      inputManualTags: 'Tags de tipo de entrada manual',
      noneInputManualTagSelected:
        'No se seleccionó ningún Tag de entrada manual',
    },
    notes: {
      title: 'Anotaciones',
      notesField: 'Campo de anotaciones',
    },
  },
  opsManagement: {
    redo: 'Recalcular la producción',
    externalCode: 'Código externo',
    description: 'Descripción',
    codeDescription: 'Código / Descripción',
    plannedExecuted: 'Producido / Planeado',
    order: 'Orden',
    inProduction: 'En Producción...',
    productionStartIn: 'La producción comenzará en',
    lastProcessing: 'Último procesado',
    validatingOrder: 'Validación de la orden de producción',
    reviewOrder: 'Esperando Revisión',
    concludedIn: 'Concluido en',
    addProductionOrder: 'Agregar orden de producción',
    filterByDate: 'Filtrar por fecha',
    filterByCode: 'Filtro por código',
    productionOrderForm: 'Formulario de orden de producción',
    typeCode: 'Ingrese el código del elemento',
    typeExternalCode: 'Ingrese el código externo del elemento',
    typeDescription: 'Ingrese descripción',
    planned: 'Cantidad planificada',
    typeMin: 'Ingrese la cantidad mínima de producción',
    typeUniqueCode: 'Ingrese un código único',
    dateToStart: 'Fecha de inicio',
    dateToEnd: 'Fecha final',
    productionList: 'Lista de producción',
    percentageTolerance: 'Tolerancia',
    produced: 'Cantidad Producida',
    reviewForm: 'Revisar artículo de orden de producción',
    review: 'Revisión',
    viewReview: 'Ver Reseña',
    calculatedQuantity: 'Cantidad Calculada',
    revisedQuantity: 'Cantidad Revisada',
  },
  manufacture: {
    duplicatedCode:
      'El código de orden ya existe para este producto, por favor elija otro',
    showDefectLocation: 'Mostrar ubicación del defecto',
    exportHorizontalList: 'Lista Horizontal',
    exportExpandedList: 'Lista Ampliada',
    serialCode: 'Código de serie',
    searchSerialByDate: 'Búsqueda de serie por periodo',
    searchPoBySerialCode: 'Búsqueda por O.P.',
    searchSerialCode: 'Búsqueda por código de serie',
    searchLot: 'Búsqueda por lote',
    extraFields: 'Campos adicionales',
    defects: 'Defectos',
    status: 'Status',
    line: 'Línea',
    product: 'Producto',
    noOpsToShow: 'Sin órdenes de producción para mostrar',
    orderCode: 'Código de orden',
    itemCode: 'Código del objeto',
    quantityPlanned: 'Cantidad planificado',
    quantityFulfilled: 'Cantidad total indicada',
    quantityGood: 'Cantidad buena',
    quantityJunk: 'Cantidad de desperdicio',
    quantityProduced: 'Cantidad producida',
    quantityAvailable: 'Cantidad disponible',
    sourceLot: 'Lote de origen',
    destinationLot: 'Lote de destino',
    defect: 'Defecto',
    itemUnit: 'Unidad',
    planner: 'Planificador',
    operator: 'Operador',
    itemDescription: 'Descripción del objeto',
    generated: 'Generado',
    planned: 'Planificado',
    scheduled: 'Programado',
    running: 'Corriendo',
    inCompletion: 'En finalización',
    finished: 'Finalizado',
    canceled: 'Cancelado',
    stageList: 'Etapas',
    step: 'Etapa',
    steps: 'Etapas',
    components: 'Componentes',
    pieceHour: 'Piezas por hora',
    location: 'Local',
    generalPoData: 'Datos generales de la orden de producción',
    srcOrderCode: 'Código de la orden de origen',
    umInventory: 'U.M. Inventario',
    umProduction: 'U.M. Producción',
    totalTime: 'Tiempo de ejecución realizado',
    startTime: 'Fecha de generación',
    generationUser: 'Usuario de generacióno',
    changeTime: 'Fecha de cambio',
    changeUser: 'Cambiar usuario',
    startHour: 'Hora de inicio',
    endHour: 'Hora de finalización',
    referenceDate: 'Fecha de referencia',
    externalCode: 'Código externo',
    production: 'Producción',
    junk: 'Basura',
    subProduct: 'Subproducto',
    consumption: 'Consumo',
    consumptionSummary: 'Resumen de componentes de consumo',
    stopsList: 'Lista de paradas',
    pO: 'O.P.',
    searchProductionOrder: 'Buscar O.P.',
    boolean: 'Estado de cumplimiento',
    optionList: 'Lista de opciones',
    validate: 'Validar',
    validateAppointment: 'Validar nota',
    notPointed: 'Sin notas',
    pointed: 'Anotado',
    itemType: 'Tipo de artículo',
    selectedOptionText: 'Texto de la opción seleccionada',
    notSelectedOptionText: 'Texto de la opción no seleccionada',
    ok: 'Conforme',
    notOk: 'No conforme',
    element: 'Elemento',
    defaultValue: 'Valor por defecto',
    checklist: 'Lista de verificación',
    frequency: 'Frecuencia',
    productCode: 'Código de producto',
    scriptCode: 'Código del guión',
    productDescription: 'Descripción del produto',
    scriptDescription: 'Descripción del guión',
    addChecklist: 'Agregar lista de verificación',
    hour: 'Por hora',
    shift: 'Por turno',
    start: 'Al empezar orden de producción',
    end: 'Al terminar orden de producción',
    volume: 'Por volumen producido',
    every: 'Cada',
    addProduct: 'Agregar producto',
    addStep: 'Agregar etapa',
    addStepItem: 'Agregar elemento de la etapa',
    addComponent: 'Agregar componente',
    addSubstituteComponent: 'Agregar componente sustituto',
    calculationStep: 'Completar el paso',
    appointmentType: 'Tipo de nota',
    productionOrder: 'Orden de producción',
    unitMeasurement: 'Unidad de medida',
    newProductionOrder: 'Nueva orden de producción',
    checkListItem: 'Elemento de la lista de verificación',
    checkListItemAlreadyAssociated:
      'Este ítem ya está asociado a una lista de control, por lo que no es posible realizar cambios.',
    itemAlreadyAssociated:
      'Este ítem ya está asociado a una ruta, por lo que no es posible realizar cambios.',
    itemAlreadyAssociatedPO:
      'Este ítem ya está asociado a una orden de producción, por lo que no es posible realizar cambios.',
    appointment: 'Nota',
    seeCompletePo: 'Ver O.P. completo',
    cancelPo: 'Cancelar O.P.',
    poCanceled: 'O.P. Cancelado',
    workInstruction: 'Instrucción de trabajo',
    variation: 'Variación',
    derivation: 'Derivación',
    newDerivation: 'Nueva derivación',
    createDerivation: '¿Está seguro de que desea crear una nueva derivación?',
    derivationInserted: 'Derivación ingresada con éxito',
    substituteProduct: 'Productos alternos',
    automatic: 'Automático',
    manual: 'Manual',
    startType: 'Tipo de inicio',
    endType: 'Tipo de fin',
    expectedStartDate: 'Fecha de inicio esperada',
    expectedEndDate: 'Fecha de fin esperada',
    startConfirmation: '¿Estás seguro de que quieres iniciar la O.P.?',
    endConfirmation: '¿Estás seguro de que quieres terminar la O.P.?',
    startPo: 'Iniciar O.P.',
    endPo: 'Enviar para completar',
    allowInstruments: 'Permitir la vinculación de instrumentos',
    groupSelectionView: 'Mostrar solo para grupos seleccionados',
    instruments: 'Herramientas',
    elements: 'Elementos',
    instrumentsIsVinculated:
      'Se utilizarán instrumentos que estén vinculados al elemento.',
    ignoreChecklist: '¿Ignorar la lista de verificación?',
    finalizationForm: 'Formulario de finalización',
    quantitative: 'Cuantitativo',
    infoText: 'Texto informativo',
    altText: 'Texto alternativo',
    additionalInformation: 'Informaciones adicionales',
    sequenceInputHelp: 'Ya existe un elemento con este valor',
    batchCreationWarning:
      'Atención: Los lotes creados no se pueden editar. ¿Desea continuar?',
    productTagHelp:
      'La tag no está asociada al árbol de elementos, lo que impide la producción automática de este producto a través de esta etiqueta en el módulo de fabricación.',
    addElement: 'Agregar elemento',
    noInstrumentsAvailable:
      'No hay ningún instrumento asociado con los elementos de la lista',
    startOfStep: 'Inicio de etapa',
    componentsWithoutAppointmentInfo:
      'Atención: Hay componentes sin referencias. ¿Desea continuar?',
    percentageExceededToleranceInfo:
      'Se ha superado el porcentaje de tolerancia. Por favor, agregue una justificación en el campo de comentarios.',
    percentageExceededToleranceInfoBlockSaveButton:
      'Se ha superado el porcentaje de tolerancia, por lo que no se puede realizar la anotación.',
    reverseStepItemNoteInfo:
      'La cantidad indicada será reembolsada por completo y esta acción no se puede deshacer. ¿Desea continuar?',
    itemsWithoutInstrument: 'Hay ítems marcados sin instrumento seleccionado',
    samples: 'Muestras',
    fixedRange: 'Banda fija',
    minRange: 'Rango mínimo',
    maxRange: 'Rango maximo',
    normalSampling: 'Muestreo normal',
    reinspectionSampling: 'Reinspección de muestreo',
    range: 'Banda',
    needToInformRange: 'Necesito informar el rango',
    approvedItems: 'Elementos aprobados: ',
    disapprovedItems: 'Elementos rechazados: ',
    pendingItems: 'Elementos pendientes: ',
    useSample: 'Uso de muestreo',
    linkTo: 'Unirse a',
    dependency: 'Dependencia',
    dependencies: 'Dependencias',
    addDependency: 'Agregar dependencia',
    triggerType: 'Tipo de disparador',
    dependsOn: 'Depende de',
    ifResult: 'Si el resultado es',
    independent: 'Independiente',
    emissionDate: 'Datos de emisión',
    functions: 'Funciones',
    allowCancelSrcOrder: 'Permitir cancelar OP',
    generateChecklist: 'Generar lista',
    checkListWithoutSampleInfo:
      'No se encontró una muestra registrada para esta lista de control, o la cantidad proporcionada está fuera de los límites permitidos.',
    orderQuantity: 'Ordene la cantidad',
    center: 'Centro',
    dateCreation: 'Fecha de creación',
    timeStandard: 'Tiempo STD',
    dependentLists: 'Listas dependientes',
    reinspectionBatch: 'Reinspección',
    appointedQuantity: 'Cantidad designada',
    samplingQuantity: 'Cantidad de muestreo',
    lotUsedIsDifferentInfo: 'Lote usado diferente del lote planeado',
    reviewers: 'Revisores',
    approvers: 'Aprobadores',
    revisionButtonInfo:
      'Solo es posible crear revisiones para listas de control que ya han sido aprobadas o que no tienen una matriz de aprobación asociada.',
    checkListItemClassification: 'Clasificación de artículos',
    checklistForm: {
      process: 'Proceso',
      observations: 'Observaciones',
      classification: 'Clasificación',
      generateByClassification: 'Generar solo elementos críticos',
      moldNumber: 'Número de molde',
      drawerRevision: 'Revisión del dibujo',
      addObservation: 'Agregar observación',
      scripts: 'Guiones',
      critical: 'Crítico',
      significant: 'Significativo',
      matrixButtons: {
        sendToReview: 'Enviar para revisión',
        sendToApprove: 'Someter para aprobacion',
        reproveReview: 'Revisión fallida',
        reprove: 'Fallar',
        approve: 'Aprobar',
        makeCurrent: 'Entrar en vigencia',
      },
      status: {
        created: 'Creado',
        sentToReview: 'Enviado a revisión',
        reviewReproved: 'Revisión rechazada',
        sentToApproval: 'Enviado a aprobación',
        approved: 'Aprobado',
        reproved: 'Rechazado',
        current: 'Actual',
        filed: 'Archivado',
      },
    },
    createdBy: 'Preparado por',
    reviewedBy: 'Revisado por',
    approvedBy: 'Aprobado por',
    effectiveFrom: 'Válido desde',
    changeHistory: 'Cambia la historia',
    checklistOptionDisabledInfo: 'La lista de control no está vigente.',
  },
  operationalSecurity: {
    riskLevel: 'Nivel de riesgo',
    impairmentMaxDuration: 'Duración máxima permitida para el impairment',
    routineTestMaxDuration: 'Duración máxima permitida prueba de rutina',
    intervalBeteweenImpairments: 'Intervalo mínimo entre dos impairments',
    routineTestRepeatTime: 'Tiempo máximo para repetir la prueba de rutina',
    frequencyMitigation: 'Frecuencia después de la mitigación',
    riskLevelWithoutMitigation: 'Nivel de riesgo sin mitigación',
    riskLevelWithMitigation: 'Nivel de riesgo después de la mitigación',
    externalCode: 'Código externo',
    externalDescription: 'Descripción externa',
    atuation: 'Actuación',
    logic: 'Lógico',
    physical: 'Físico',
    asynchronousInstruments:
      'Instrumentos que no pueden funcionar simultáneamente',
    isRoutineTest: '¿Prueba de rutina?',
    testPeriodicity: 'Frecuencia de prueba',
    weekly: 'Semanalmente',
    biweekly: 'Quincenal',
    monthly: 'Mensual',
    semiannual: 'Semi anual',
    yearly: 'Anual',
    criticalInstrumentTag: 'Tag vinculada con el instrumento crítico',
    controlLoopTag: 'Tag vinculada a la malla de control',
    interlockTag: 'Tag vinculada con el enclavamiento',
    requesters: 'Solicitantes',
    requestersAndPerformers: 'Solicitantes e ejecutantes',
    approvers: 'Aprobadores',
    solicitationForm: 'Formulario de solicitud',
    createdAt: 'Solicitar fecha de creación',
    observations: 'Comentarios',
    addImpairment: 'Agregar impairment',
    lastTest: 'Última prueba',
    tests: 'Pruebas',
    impairmentWithMitigation:
      'Solo es posible agregar deterioro a este instrumento si existe una medida de mitigación',
    requesterPermission:
      'Solo los solicitantes de este instrumento pueden crear pruebas de rutina',
    unfinishedRoutineTest: ' Ya hay una prueba en curso para este instrumento',
    onlineMonitoring: 'Monitoreo en línea',
    withoutMitigation: 'Sin mitigación',
    generatingFile: 'Generar archivo...',
    impairment: {
      impairment: 'impairment',
      mitigationMeasureQuestion: '¿Se implementarán medidas de mitigación?',
      routineTestMitigation: '¿Se implementarán medidas de mitigación?',
      impairmentJustification: 'justificación de la prueba',
      minimumTimeInformation:
        'O impairment não atende ao período mínimo requerido entre dos impairments de um mesmo instrumento. Se enviará una solicitud para un nivel superior a la escala de aprobación. Deseja prosseguir?',
      concurrentInstrumentInformation:
        'O impairment no cumple los requisitos de simultaneidad de impairments. La solicitud se enviará a un nivel superior de la matriz de aprobación. Quieres proceder?',
      concurrentInstruments: 'Instrumentos simultáneos',
      risks: 'Riesgo',
      mitigationMeasure: 'Medida de mitigación',
      characterLimit: 'Límite de caracteres: 2000',
      solicitationDate: 'Fecha de solicitud',
      restoreDate: 'Fecha de restauración',
      requester: 'Solicitante',
      approver: 'Aprobador',
      approvalStatus: 'Estado de aprobación',
      executionStatus: 'Estado de ejecución',
      solicitation: 'Solicitud',
      reproveJustification: 'Justificativa para reprobação',
      approveSolicitation: 'aprobar solicitud',
      execution: 'Ejecución',
      instrument: 'Instrumento',
      approved: 'Aprobado',
      waiting: 'Esperando',
      waitingExtension: 'Esperando expansión',
      processing: 'Operando',
      disapproved: 'Desaprobado',
      routineTest: 'Teste de rotina',
      late: 'Desactivado/Atrasado',
      onTime: 'Desactivado/En el plazo',
      approvedAt: 'Fecha de aprobación',
      disapprovedAt: 'Fecha de rechazo',
      approvedDisapproved: 'Fecha de aprobación/rechazo',
      executed: 'Ejecutado',
      notExecuted: 'Sin ejecutar',
      tested: 'Probado',
      notTested: 'No probado',
      executedAt: 'Fecha de ejecución',
      restorationLimit: 'Fecha límite para la restauración',
      instrumentState: 'Status actual del instrumento',
      durationExtensionForm: 'Formulario de extensión de duración',
      invalidDurationMessage:
        'La fecha debe ser mayor que la fecha actual para restablecimiento',
      extendTo: 'Ampliar el plazo para',
      instrumentOperatingStatus: 'Estado operativo del instrumento',
      controlLoopStatus: 'Estado de la malla de control',
      interlockStatus: 'Estado de interbloqueo',
      inoperative: 'Inoperante',
      operant: 'Operante',
      manual: 'Manual',
      automatic: 'Automático',
      disabled: 'Desactivado',
      able: 'Habilitado',
      concurrentLevelIncrease: 'Aumento de nivel debido a la concurrencia',
      maxTimeRequestLevelIncrease:
        'Aumento de nivel debido al aumento de duración',
      currentLevel: 'Nivel actual',
    },
    restoration: {
      beenRestored: '¿Se ha restaurado el instrumento?',
      restoredAt: 'Fecha de restauración',
      restoration: 'Restauracion',
      restored: 'Restaurado',
      notRestored: 'No restaurado',
      waiting: 'Esperando',
    },
    execution: {
      date: 'Fecha',
      wasExecuted: 'O impairment fue realizado?',
      executedAt: 'Fecha de ejecución',
    },
    routineTest: {
      testJustification: 'Impairment Justification',
      testMaxDuration: 'Duración máxima permitida para la prueba',
      routineTests: 'Pruebas de rutina',
      routineTest: 'Prueba de rutina',
      routineTestQuestion: '¿Se realizó una prueba de rutina?',
      impairmentDuringTestQuestion:
        '¿Se realizará el deterioro durante la prueba?',
      restorationQuestion: '¿Se restaura el SCI después de la prueba?',
      successfulQuestion: '¿Fue exitosa la prueba?',
      repeatQuestion: '¿Será necesario repetir la prueba?',
      instrumentPassedQuestion:
        '¿El instrumento superó satisfactoriamente la prueba?',
      testLimitDate: 'Fecha límite de prueba',
      repeatTestLimit: 'Tienes {time} para repetir la prueba',
      completedOk: 'Concluido - en el plazo',
      completedLate: 'Concluido - atrasado',
      waitingOk: 'Esperando - en el plazo',
      waitingLate: 'Esperando - atrasado',
    },
  },
  managementOfChanges: {
    noAreaAvailable: 'No hay áreas registradas',
    ssma: 'SSMA',
    engineering: 'Ingeniería',
    industry: 'Area Industrial',
    mocNumber: 'Número de MOC',
    area: 'Área',
    solicitation: 'Solicitación',
    requester: 'Solicitante',
    standardArea: 'Área estándar',
    criticalArea: 'Área Crítica',
    addSolicitation: 'Crear solicitud',
    nameOfMOC: 'Nombre de la MOC',
    type: 'Tipo',
    model: 'Modelo',
    emergency: 'Emergencia',
    technique: 'Técnica',
    default: 'Normal',
    temporary: 'Temporario',
    deadline: 'Plazo final',
    currentModelDescription: 'Descripción del modelo actual',
    changeDescription: 'Descripción del cambio propuesto',
    mocSolicitation: 'Solicitud de la MOC',
    riskAssessment: 'Evaluación de riesgos',
    riskList: 'Lista de Riesgos',
    risk: 'Riesgo',
    probability: 'Probabilidad',
    comments: 'Comentarios',
    notApplicable: 'n/a',
    actionPlan: {
      title: 'Plan de acción',
      progressStatus: 'Status de andamento',
      deadlineStatus: 'Estado del plazo',
      executed: 'Concluido',
      waiting: 'Esperando',
    },
    additionalInformation: 'Informaciones adicionales',
    isCriticalArea: 'El área MOC solicitada',
    responsibleArea: 'Área responsable',
    responsibleUser: 'Usuario responsable',
    initialDate: 'Fecha de inicio',
    durationInDays: 'Duración en días',
    expectedDate: 'Fecha prevista',
    endDate: 'Fecha final',
    observation: 'Nota',
    approvalSector: {
      ssma: 'Formulario de aprobación SSMA',
      engineering: 'Formulario de aprobación ingeniería',
      industrialArea: 'Formulario de aprobación área industrial',
    },
    approver: 'Aprobador',
    authorizer: 'Usuario que autoriza',
    approved: 'Aprobado',
    waiting: 'Esperando',
    disapproved: 'Desaprobado',
    onTime: 'En el plazo',
    late: 'Atrasado',
    executed: 'Ejecutado',
    waitingExecution: 'Esperando ejecución',
    execution: 'Ejecución',
    restoration: 'Restauracion',
    changeWasExecuted: '¿Se ha ejecutado el cambio?',
    changeWasRestored: '¿Se ha restablecido el cambio?',
    approvals: 'Aprobaciones',
    finalStatus: 'Status final',
    changeImplementation: 'Implementación de MOC',
    restored: 'Restaurado',
    notRestored: 'No restaurado',
    notStarted: 'No empezado',
    inProgress: 'En proceso',
    actionPlanStatus: 'Estado del plan de acción',
    changeStatus: 'Estado general de la solicitud',
    waitingApproval: 'Esperando aprovación',
    waitingRestoration: 'Esperando restauracion',
    notExecuted: 'Sin ejecutar',
    restorationOnTime: 'Reincorporado a tiempo',
    restorationLate: 'Reincorporado en mora',
    riskLegend: '5 = muy probable; 1 = poco probable; n/a = no se aplica',
    maxLength200: 'El límite de caracteres es 200',
    maxLength2000: 'El límite de caracteres es 2000',
    cancelMoc: 'Cancelar MOC',
    canceledMoc: 'MOC cancelada',
    canceled: 'Cancelado',
    cancelConfirmation: '¿Seguro que quieres cancelar este MOC?',
    solicitationDate: 'Fecha de solicitud',
    mocPerformer: 'Ejecutor MOC',
    restorationPerformer: 'Ejecutor de restauración',
    approvers: 'Aprobadores',
  },
  scale: {
    ticketNumber: 'Numero de ticket',
    dateEntry: 'Fecha de entrada',
    dateOut: 'Fecha de salida',
    weight: 'Peso líquido',
    board: 'Dashboard',
    inputCode: 'Codigo del insumo',
    input: 'Insumo',
    provider: 'Proveedor',
    truck: 'Camión',
    conveyer: 'Transportador',
    nfeWeight: 'Peso Nota',
    nf: 'Nota fiscal',
    filterByDate: 'Filtrar por fecha',
  },
  analytics: {
    comparativeBetweenPeriods: {
      skew: 'Asimetría',
      kurtosis: 'Curtosis',
      shapiro: 'Valor p (prueba de normalidad de Shapiro-Wilk)',
      varianceGlobal: 'Varianza global',
      standardDeviationGlobal: 'Desviación estándar global',
      addGroup: 'Añadir grupo',
      overlaid: 'Superpuesto',
      parameter: 'Parámetro',
      groupOne: 'Grupo 1',
    },
    correlation: {
      title: 'Correlación',
      regressionLine: 'Línea de regresión',
      absoluteValue: 'Valor absoluto',
      a: 'A',
    },
    eventManager: {
      addedEvent:
        'Evento añadido en la cola de procesamiento, esto puede tomar algunos minutos!',
      remakeError: 'Hubo un error al recrear el evento, intente una vez más!',
      remakeSucess: 'Evento recreado con suceso!',
      selectFrame: 'Seleccione un evento con datos para generar el gráfico',
      recreateEvents: '¿Seguro que quieres recrear los eventos?',
      recreate: 'Recrear',
      open: 'Abierto',
      close: 'Cerrado',
      canceled: 'Anulado',
      moreInformation: 'Seleccione un evento para ver más información',
      closingDate: 'Fecha de cierre',
      insertType: 'Tipo de inserción',
      auto: 'Automático',
      manual: 'Manual',
      alertCalendarActive:
        'El período visto tiene un calendario activo, por lo que es posible que no se muestren algunos eventos.',
      showCalendar: 'Mostrar calendario',
      divided: 'Dividido',
      openEventDescription:
        'La fecha/hora de finalización permitida es la hora actual, pero puede haber retrasos en la identificación automática de la finalización del evento, lo que hace que la hora de finalización real difiera de la hora seleccionada cuando se procesa el evento',
      configuration: {
        itemCode: 'Identificación del elemento',
        itemDescription: 'Descripción del elemento',
        selectAlert: 'Seleccione una alerta',
        rangeIn: 'Intervalo (IN)',
        rangeOut: 'Intervalo (APAGADO)',
        maxTimeExceeded: 'Se excedió el tiempo máximo',
        equipmentManagement: 'Gestión de equipos',
        active: 'Activo',
        preview: 'Vista Previa',
        conditionsStart: 'Condiciones - inicio',
        conditionsEnd: 'Condiciones - fin',
        advancedOptions: 'Opciones avanzadas',
        time: 'Hora',
        minDuration: 'Tiempo de duración mínima',
        inSeconds: 'En segundos',
        seconds: 'segundos',
        minInterval: 'Intervalo mínimo entre eventos',
        maxTimeMicroStop: 'Tiempo máximo de micro parada',
        executionInterval: 'Intervalo de ejecución',
        lastExecution: 'Última ejecución',
        minutes: 'minutos',
        standardMicroStop: 'Micro parada de motivo estándar',
        complementaryTypes: 'Tipos complementarios',
        motivesGroup: 'Grupo de Motivos',
        selectItem: 'selecciona un elemento',
        typeHelp:
          'Los elementos complementarios directamente asociados con el evento aparecen en el registro de parada independientemente del motivo seleccionado.',
        referenceValueHelp:
          'Valor de referencia para verificar el siguiente punto después de que comience el evento.',
        breakDay: 'Terminar el evento al final del día',
        motives: {
          planned: 'Planificado',
          motiveCode: 'Código de motivo',
          motiveCodeDuplicate: 'El código de motivo ya está en uso',
          motiveDescription: 'Descripción de la motivo',
          needApprove: 'Necesita aprobación',
        },
        permissions: 'Permisos',
        relatedFrames: 'Eventos relacionados',
        relatedFramesTime: 'Diferencia en segundos de eventos',
        userPermissions: 'Permisos para usuarios',
        enableCancelStopping: 'Permitir anular parada',
        alreadyAssociatedAutomaticTag:
          'Ya hay un grupo de autoapuntado asociado',
      },
    },
    operator: {
      title: 'Operador',
      shiftScheduling: 'Programación de turnos',
      noneOperator: 'Ningún operador programado para este período',
      fillDates: 'Reproducir para próximas fechas',
      duplicatedScheduleMessage:
        'Imposible continuar, programación ya registrada en la unidad',
      showGoal: 'Muestra objetivo',
      hideGoal: 'Oculta Objetivo',
      column: {
        goal: 'Objetivo',
      },
    },
    report: {
      diary: 'diario',
      weekly: 'Semanal',
      monthly: 'Mensual',
      accumulated: 'Acumulado',
    },
    process: {
      recalculateQuestion: '¿Quiere volver a calcular los puntos convertidos?',
      recalculate: 'Recalcular',
      reconsolidateQuestion:
        '¿Quiere volver a calcular los puntos consolidados?',
      reconsolidate: 'Reconsolidar',
    },
  },
  rootCause: {
    statusHistory: 'Historial de estado',
    active: 'Activo',
    inactive: 'Inactivo',
    rule: 'Regla',
    maxTimeStatus: 'Sin completar en {time} horas',
    removed: '¡Eliminado con éxito!',
    effectNotFound: 'Este efecto no se pudo encontrar',
    fiveWTwoH: '5W, 2H',
    deleteConfirmation:
      '¿Está seguro de que desea eliminar este problema / efecto?',
    causeConfirmation: '¿Está seguro de que desea cancelar esta causa?',
    removeCauseConfirmation: '¿Está seguro de que desea eliminar esta causa?',
    removeProblemEffect: 'Eliminar este problema / efecto',
    causeError: 'Hubo un problema al guardar la causa. Vuelve a intentarlo ...',
    insertCause: 'Insertar causa',
    waitingConclusion: 'Esperando finalización',
    pendingVerification: 'Verificación pendiente',
    checkedEffective: 'Verificado - efectivo',
    checkedIneffective: 'Verificado - ineficaz',
    efficiencyError: 'Error al intentar ahorrar eficiencia',
    attachmentError:
      'Error al intentar eliminar el archivo adjunto por eficiencia',
    late: 'Atrasado',
    onTime: 'En el plazo',
    efficiencyAction: 'Efectividad de la acción',
    responsable: 'Responsable',
    examiner: 'Verificador',
    change: 'Cambio',
    status: 'Estado de verificación',
    approvalStatus: 'Estado de aprobación',
    verificationDate: 'Fecha de verificación',
    selectDate: 'Seleccione la fecha',
    commentary: 'Comentario',
    conclusionDate: 'Fecha de finalizacion',
    writeCommentary: 'Escribe una reseña',
    editActionNotAllowed:
      'No está permitido editar una acción que ya ha sido completada o cancelada',
    attachments: 'Archivos adjuntos',
    saveAvaliation: 'Guardar reseña',
    needStatus:
      'Es necesario informar el estado junto con la fecha de verificación',
    canceled: 'Cancelado',
    causeCanceled: 'Causa cancelada',
    causeCanceledDescription:
      'Esta causa ha sido cancelada, las actividades de esta causa serán suspendidas',
    noneRootCause: 'No hay causas raíces',
    completed: 'Concluido',
    pending: 'Pendiente',
    open: 'Abrir',
    removeActionConfirmation:
      '¿Estás seguro de que deseas eliminar esta acción?',
    situation: 'Situación',
    manager: 'Gerente',
    applicator: 'Aplicador',
    deadline: 'Fecha límite',
    why: 'Vincular con por qué',
    listActionsError:
      'Hubo un problema al intentar enumerar las acciones. Vuelve a intentarlo.',
    actionPlan: 'Plan de acción',
    addAction: 'Agregar acción',
    writeTitle: 'Escribe un título para esta acción.',
    actionDetails: 'Detalles de la acción',
    whatToDo: 'Lo que debe hacerse',
    whyToDo: 'Por qué debería hacerse',
    howToDo: 'Como se debe hacer',
    assistance: 'Guarnición',
    whysSave: 'Por qué se guardó correctamente',
    whys: 'Porques',
    emptyWhys: 'No se permite guardar con sockets vacíos',
    origin: 'Fuente',
    cause: 'Porque',
    causes: 'Causas',
    addCause: 'Agregar causa',
    classification: 'Clasificación',
    gravity: 'Gravedad',
    urgency: 'Urgencia',
    tendency: 'Tendencia',
    searchUser: 'Buscar usuario',
    allWithFilter: 'Todo (con filtro)',
    lastFive: 'Últimos 5 registrados (sin filtro)',
    successEffect: '¡Efecto agregado con éxito!',
    errorNewEffect: 'Error al intentar crear un nuevo efecto',
    errorRequestEffect:
      'Hubo un problema al solicitar los efectos. Vuelve a intentarlo.',
    addEffect: 'Agregar efecto',
    analysisStatus: 'Estado de análisis',
    byDescription: 'Por descripción',
    filterByDescription: 'Filtrar por descripción',
    effectForm: 'Forma de efecto',
    effectDescription: 'Descripción del efecto',
    addExaminer: 'Agregar verificador',
    errorListActions:
      'Hubo un problema al intentar enumerar las acciones. Vuelve a intentarlo.',
    registerIn: 'Unido',
    access: 'Acceso',
    efficiencySituation: 'Estado de análisis general',
    noDate: 'Sin fecha establecida',
    addAttachment: 'Haga clic para agregar un archivo adjunto',
    file: 'archivo',
    tagsGroup: 'Grupo de tags',
    generateAnalysis: 'Generar análisis',
    generateRootCauseAnalysis: 'Generar análisis de causa raíz',
    errorGetEffects:
      'Error al intentar consultar los efectos registrados, inténtelo de nuevo',
    createdAt: 'Causa raíz registrada en:',
    goToRootCause: 'Ir a la causa raíz',
    verificationDeadline: 'Fecha límite para la verificación',
    sendToApprove: 'Someter para aprobación',
    alreadySentToApprove: 'Presentado para su aprobación',
    approvedApproval: 'Aprobado',
    disapprovedApproval: 'Desaprobado',
    canceledStatus: 'Cancelado',
    notStartedOk: 'No empezado - en el plazo',
    notStartedLate: 'No empezado - atrasado',
    inProgressOk: 'En proceso - en el plazo',
    inProgressLate: 'En proceso - atrasado',
    pendingVerificationOk: 'Pendiente de verificación - en el plazo',
    pendingVerificationLate: 'Pendiente de verificación - atrasado',
    completedOk: 'Concluido - en el plazo',
    completedLate: 'Concluido - atrasado',
    ishikawa: 'Ishikawa',
    efficiency: 'Eficiencia',
    extremelySerious: 'Extremadamente serio',
    verySerious: 'Muy serio',
    serious: 'Serio',
    lowSerious: 'No es serio',
    notSerious: 'Ingrávido',
    worseQuickly: 'Empeorará rápidamente',
    worseInShortPeriod: 'Empeorará a corto plazo',
    worseInMediumPeriod: 'Empeorará a medio plazo',
    worseInLongPeriod: 'Empeorará a la larga',
    notChange: 'No cambiará',
    needActionNow: 'Necesidad de acción inmediata',
    veryUrgent: 'Muy urgente',
    urgentShortPeriod: 'Urgente, atención a corto plazo',
    lowUrgent: 'Poco urgente',
    canWait: 'Puede esperar',
    machine: 'Máquina',
    environment: 'Medio ambiente',
    method: 'Método',
    measure: 'Medida',
    labor: 'Mano de obra',
    feedStock: 'Materia prima',
    approved: 'Aprobado',
    disapproved: 'Desaprobado',
    waitingApproval: 'Esperando aprovación',
    indicator: 'Indicador',
    selectUser: 'Seleccionar usuario',
    mostRecent: 'Más reciente:',
    formatMustBe: 'El formato debe ser HH:mm o HHH:mm',
    description: 'Descripción:',
    tag: 'Tag:',
    useActionPlanLimits: 'Utilice los límites del plan de acción',
    allowSimultaneousAnalyzes: 'Permitir generar análisis concurrentes',
    pointsOutOfBounds: 'Número de puntos consecutivos fuera del límite',
    dateFieldOrientation: 'Usar orientación de fecha vertical',
    needToSave:
      'Para poder agregar los porqués del "niño", primero debes guardar los porqués de los "padres"',
  },
  dashboard: {
    useGoal: 'Usar objetivo',
    fixIndicatorColumn: 'Bloquear indicadores',
    deleteDashboard: 'El dashboard ha sido eliminado',
    errorDeleteDashboard: 'Hubo un problema al intentar eliminar el dashboard',
    deleteDashboardConfirmation:
      '¿Está seguro de que desea eliminar este dashboard?',
    fullscreen: 'Pantalla completa',
    exitFullscreen: 'Salir de pantalla completa',
    reproduction: 'Reproducir',
    exitReproduction: 'Salir del modo tv',
    startTvMode: 'Modo tv - dashboard',
    startTvModeTab: 'Modo tv - pestaña actual',
    tvMode: 'Modo TV',
    editDashboard: 'Editar dashboard',
    excludeDashboard: 'Eliminar dashboard',
    unauthorized: 'Lo sentimos, no está autorizado para acceder a esta página',
    initialPage: 'Página de inicio',
    daysReleased: 'Días liberados',
    currentDay: 'Día actual',
    dashboardForm: 'Formulario de dashboard',
    writeDescription: 'Por favor ingrese la descripción',
    switchTabTimer: 'Hora de cambio de pestaña',
    switchTabTimerDescription:
      'Tiempo en segundos que se mostrará cada pestaña en el modo tv',
    autoLoadingDescription:
      'Cargue gráficos de todas las pestañas cuando abra el dashboard',
    autoLoading: 'Cargar automáticamente',
    typeDescription: 'Tipo del Dashboard',
    typeDefaultLabel: 'Dashboard estándar',
    typeAppLabel: 'Dashboard Aplicación',
    typeHmiLabel: 'Dashboard IHM',
    userDashboardAccess: 'Usuarios que tendrán acceso al dashboard',
    lastUpdate: 'Última actualización',
    ll: 'LI',
    ul: 'LS',
    upper: 'Superior',
    lower: 'Inferior',
    goal: 'Meta',
    componentDeleted: 'El componente ha sido eliminado',
    componentDeleteError:
      'Hubo un problema al intentar eliminar el componente.',
    updateData: 'Actualizar datos',
    editComponent: 'Editar componente',
    deleteComponent: 'Eliminar componente',
    noIndicators: 'Sin indicadores',
    zIndexUpButton: 'Traer a frente',
    zIndexDownButton: 'Mandar a detrás',
    alignTop: 'Alinear hacia arriba',
    alignLeft: 'Alinear a la izquierda',
    alignBottom: 'Alinear hacia abajo',
    alignRight: 'Alinear a la derecha',
    alignCenterVertical: 'Centrar verticalmente',
    alignCenterHorizontal: 'Centrar horizontalmente',
    link: 'Enlace',
    componentType: 'Tipo de componente',
    subTitle: 'Subtítulo',
    prefix: 'Prefijo',
    suffix: 'Sufijo',
    refreshDelay: 'Tiempo de actualización de datos',
    refreshDelayDescription:
      'Tiempo en segundos del intervalo de actualización de datos en modo tv',
    direction: 'Dirección',
    aggregationPeriod: 'Período de agregación',
    dateFormat: 'Formato de la fecha',
    updateDate: 'Fecha de actualización',
    legend: 'Leyenda',
    serieAmplitude: 'Amplitud de la serie de datos',
    goalPercentage: 'Tolerancia a la meta',
    goalPercentageInfo:
      'Si el objetivo es entre pistas, el porcentaje se basará en la diferencia entre las pistas',
    currentShift: 'Turno actual',
    currentWeek: 'Semana actual',
    currentMonth: 'Mes actual',
    currentHarvest: 'Cosecha actual (*)',
    currentYear: 'Año corriente',
    last: 'Últimos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    useCompleteAmplitude: 'Usar intervalo completo',
    useFullHour: 'Usar hora entera',
    componentForm: 'Formulario de componente',
    advancedOptions: 'Opciones avanzadas',
    unit: 'unidad',
    selectOtherUnitData: 'Seleccione otra unidad para usar sus datos',
    events: 'Eventos',
    lowerChartLimit: 'Límite inferior del gráfico',
    upperChartLimit: 'Límite superior del gráfico',
    loadingFile: 'Cargando archivo ...',
    isRequired: 'es obligatorio',
    ganttNewDataTitle: 'abc',
    colorCode: 'Codigo de color',
    colorTypeLabel: 'Tipo',
    initialValue: 'Valor inicial',
    finalValue: 'Valor final',
    color: 'Color',
    goalColor: 'Color al llegar a la meta',
    cancelEditConfirmation: '¿Quieres cancelar la edición?',
    addColor: 'Añadir color',
    limitsColor: 'Color del borde',
    current: 'Actual',
    indicator: 'Indicador',
    calculation: 'Cálculo',
    tagsListFromUnit: 'Esta lista contiene Tags de unidad',
    valuesToDisplay: 'Valores para mostrar',
    aggregation: 'Agregación',
    serieValues: 'Valores de la serie',
    compareWith: 'Comparar con',
    none: 'ninguna',
    graphType: 'Tipo de gráfico',
    textFormat: 'Formato del texto',
    textSize: 'Tamaño del texto',
    displaySize: 'Tamaño de la pantalla',
    limitSize: 'Tamaño límite',
    colors: 'Colores',
    backgroundColor: 'Color de fondo',
    showUpperLimit: 'Muestra el límite superior',
    showLowerLimit: 'Muestra el límite inferior',
    showGoal: 'Muestra objetivo',
    showLabelGoal: 'Mostrar el valor del objetivo',
    showValue: 'Muestra valor',
    order: 'Orden',
    indicatorExcluded: 'El indicador ha sido eliminado',
    indicators: 'Indicadores',
    addIndicator: 'Añadir indicador',
    indicatorForm: 'Formulario de indicador',
    inOutLimits: 'Límites dentro/fuera',
    inOutReference: 'Referencia dentro/fuera',
    out: 'Fuera',
    in: 'Dentro',
    motiveGroup: 'Grupo de motivos',
    yAxisValue: 'Valor eixo y',
    hoursStop: 'Horas paradas',
    stop: 'Parada',
    available: 'Disponible',
    showAccumulated: 'Visualización acumulativa',
    importing: 'Importando ...',
    widgetImported: '¡Widget importado correctamente!',
    errorImportingFile:
      'Hubo un error en la importación, revise el archivo y vuelva a intentarlo.',
    widgetDeleted: 'El widget ha sido eliminado',
    errorDeletingWidget: 'Hubo un problema al intentar eliminar el widget.',
    widgetPermission:
      'Este widget solo es visible para los usuarios autorizados a administrar el panel',
    addWidget: 'Agregar widget',
    importWidget: 'Importar widget',
    tabForm: 'Formulario de pestaña',
    allDays: 'Todos los días',
    tabImported: '¡Pestaña importada correctamente!',
    tabDeleted: 'La pestaña fue eliminada',
    errorDeletingTab: 'Hubo un problema al intentar eliminar la pestaña',
    addTab: 'Agregar pestaña',
    importTab: 'Importar pestaña',
    exportTab: 'Exportar pestaña',
    editTab: 'Editar pestaña',
    deleteTab: 'Eliminar pestaña',
    exportPdf: 'Exportar para PDF',
    noneTabs: 'No se crean pestañas en el panel',
    createTab: 'Crear pestaña',
    addComponent: 'Agregar componente',
    exportWidget: 'Exportar widget',
    editWidget: 'Editar widget',
    deleteWidget: 'Excluir widget',
    widgetForm: 'Formulario de widget',
    columnDataCalc: 'Columna con datos calculados',
    tagDescriptionWidth: 'Ancho de columna de referencia',
    meanAndSum: 'Promedio y suma',
    logbookGroup: 'Grupo de anotaciones',
    filterByTitle: 'Filtrar por título',
    openDashboard: 'Dashboard abierto',
    indicatorType: 'Tipo de indicador',
    joinEvents: 'Fusionar datos del indicador',
    dashboardPermission:
      'Este card solo es visible para los usuarios autorizados a administrar el panel',
    icon: 'Icono',
    dynamicIndicator: 'Indicador dinámico',
    dynamicIndicatorDescription:
      'Lo indicador dinámico se usa para cambiar los indicadores según su valor',
    emissionDate: 'Fecha de emisión',
    referenceDate: 'Fecha de referencia',
    showAvailable: 'mostrar (En operación)',
    showLegendDescription: 'Mostrar descripción en la leyenda',
    showAllMotives: 'Mostrar todos los motivos en la leyenda',
    specificationLimit: 'Límites de especificación',
    actionLimit: 'Límites del plan de acción',
    spc: 'CPE',
    reference: 'Referencia',
    groupByCalculation: 'Necesario para agrupar para la etiqueta de cálculo',
    dynamicPeriod: 'Período dinámico',
    externalAggregation: 'Agregación externa',
    externalAggregationHelp:
      'Al habilitar se desplegará un selector de agregación en el componente y por defecto se obedecerá la agrupación del indicador de secuencia 1 o el componente',
    showControlLimits: 'Muestra los límites de control (CPE)',
    controlLimits: 'Límites de control (CPE)',
    zeroColor: 'Banda de color - 0%',
    fiftyColor: 'Banda de color - 50%',
    oneHundreadColor: 'Banda de color - 100%',
    controlLimitsInfo:
      'Límite de control inferior: {lowerLimit} - límite de control superior: {upperLimit}',
    specificationLimitsInfo:
      'Límite de especificación inferior: {lowerLimit} - Límite de especificación superior: {upperLimit}',
    uniqueGoalInfo: 'Meta: {goal}',
    lowerUpperGoalInfo: 'Meta abajo: {lowerGoal} - meta más alto {upperGoal}',
    noneToCompare: 'No hay rango actual',
    colorType: {
      free: 'Libre',
      specificationLimit: 'Límite de especificación',
      goal: 'Meta',
    },
    componentTypes: {
      labels: {
        chart: 'Gráfico',
        pareto: 'Pareto',
        pizza: 'Gráfico de pizza',
        gauge: 'Velocímetro',
        solidGauge: 'Velocímetro gradiente',
        grid: 'Cuadrícula',
        imageConditional: 'Imagen condicional',
        staticImage: 'Imagem estática',
        text: 'Texto',
        gav: 'Gestión de objetivos',
        status: 'Estado del TAG',
        gantt: 'Gantt',
        eventManager: 'Gestión de eventos',
        report: 'Reporte',
        managementCard: 'Gestión del rendimiento',
        logbook: 'Anotaciones',
        bulletChart: 'Gráfico de viñetas',
        paretoChart: 'Gráfico de pareto',
        treemap: 'Mapa de arboles',
        operatorChart: 'Gráfico de análisis del operador',
      },
      descriptions: {
        chart:
          'Muestra los puntos de datos de la serie temporal en forma de líneas o barras verticales. El usuario debe seleccionar el intervalo de la serie y si mostrar datos agregados o puntos individuales en la serie.',
        pareto:
          'Muestra el diagrama de Pareto de los motivos señalados en la cadena de control de parada seleccionada. El usuario debe seleccionar la amplitud de los motivos indicados y la forma en que se presentarán los valores de las horas detenidas (porcentaje o absoluto)',
        pizza:
          'Muestra el gráfico circular de los motivos señalados en la cadena de control de parada seleccionada. El usuario debe seleccionar la amplitud de los motivos mencionados',
        gauge:
          "Muestra el indicador seleccionado en formato de velocímetro, lo que requiere que el usuario defina los límites de trabajo ideales (región verde). El sistema calculará la zona de atención '(región amarilla) y la zona fuera de especificación' (región roja)",
        solidGauge:
          "Muestra el indicador seleccionado en el formato de velocímetro, con el componente pasando por los diferentes colores de su gradiente de acuerdo con la definición de 'más grande mejor' o 'más pequeño mejor' definida por el usuario en la configuración del indicador",
        grid: 'Muestra los valores del indicador dentro del intervalo seleccionado por el usuario en el formato de tabla, siendo posible seleccionar mostrar los puntos agregados en un período seleccionado o sus valores individuales',
        image:
          'Muestra una imagen condicional insertada en el sistema por el usuario a partir del resultado de una ecuación definida en el indicador',
        staticImage:
          'Muestra una imagen estática insertada por el usuario. Utilizada para exhibir imagens de equipos, logo o objetos.',
        text: 'Muestra el resultado del indicador seleccionado dentro del intervalo definido por el usuario en el formato de valor numérico, y también es posible que el usuario ingrese ecuaciones sin tener que definir nuevos indicadores.',
        gav: 'Muestra el valor del indicador seleccionado y lo compara con el valor objetivo, mostrando un signo positivo o negativo según el resultado de esta combinación.',
        status:
          "Muestra el valor del indicador seleccionado y lo compara con el valor de los límites de control, definidos en la 'configuración del indicador, mostrando un signo positivo o negativo según el resultado de esta combinación",
        gantt:
          'Muestra el diagrama de Gantt, que se utiliza para ilustrar el progreso de las diferentes etapas de los datos presentados.',
        eventManager:
          'Muestra el gráfico de la cadena de control seleccionada, mostrando los eventos generados dentro del período seleccionado',
        report:
          'Muestra los valores agregados o calculados de los indicadores seleccionados para los períodos definidos en la configuración del reporte.',
        managementCard:
          'Muestra el último valor del indicador y lo compara con los límites definidos en la configuración del indicador. Además, muestra los porcentajes de tiempo que cada turno pasó fuera del rango de límites.',
        logbook:
          'Muestra las anotaciones realizadas dentro del período definido para el grupo de anotaciones seleccionado',
        bulletChart:
          'Los gráficos con viñetas permiten a los lectores comparar rápidamente un valor único con un valor objetivo. El valor objetivo se define en la configuración de objetivos de la etiqueta.',
        paretoChart:
          'El gráfico de Pareto es un gráfico de columnas que ordena las frecuencias de las ocurrencias, de mayor a menor, lo que permite priorizar los problemas',
        treemap:
          'El mapa de arboles ordena las frecuencias de las ocurrencias, de mayor a menor, lo que permite priorizar los problemas',
        operatorChart:
          'El gráfico de operadores y etiquetas muestra datos que relacionan las etiquetas con los operadores y sus turnos programados. Este gráfico pretende mostrar la agregación de etiquetas para operadores, dependiendo del turno de trabajo',
      },
    },
  },
  accessControl: {
    register: 'Registros',
    operation: 'Operación',
    routine: 'Rutina',
    initialPage: 'Página de inicio',
    users: {
      login: 'Iniciar sesión',
      name: 'Nombre',
      type: 'Tipo',
      adm: 'Administrador',
      active: 'Activo',
      maxLengthExceeded: 'Se excedió el tamaño máximo',
      email: 'Email',
      insertValidEmail: 'Introduzca un correo electrónico válido',
      accountType: 'Tipo de cuenta',
      activeUser: 'Usuario activo',
      unblockUser: 'Desbloquear usuario',
      pngJpg: '¡Solo puede cargar archivos PNG / JPG!',
      lessTwoMb: '¡La imagen debe tener menos de 2 MB!',
      passwordExtra: 'Ingrese una nueva contraseña para cambiar la actual',
      passwordCorrection:
        'Introduzca una nueva contraseña para desbloquear el usuario',
      upload: 'Enviar',
      validatePassword: {
        minimumCaracters: 'La contraseña debe tener más de 8 caracteres',
        upperCase: 'La contraseña debe tener al menos una letra mayúscula',
        lowerCase: 'La contraseña debe tener al menos una letra minúscula',
        number: 'La contraseña debe tener al menos un número',
        specialCaracter:
          'La contraseña debe tener al menos un carácter especial',
      },
    },
  },
  calendar: {
    productiveHour: 'Hora productiva',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    period: 'Curso del tiempo',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    weekDays: 'Dias de la semana',
  },
  dateHour: {
    dateAndHourSuccess: 'Las horas y las fechas cambiaron correctamente',
    shiftStart: 'Inicio de turno',
    endShift: 'Fin del turno',
    unitDateAndHourConfiguration:
      'Configuración de la hora y la fecha de la unidad',
    startDay: 'Hora de inicio del día',
    selectHour: 'Seleccionar hora',
    startWeek: 'Inicio de la semana',
    selectDay: 'Seleccione el dia',
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    harvestPeriod: 'Periodo de cosecha',
    timezone: 'Tiempo estándar',
    selectTimezone: 'Seleccionar hora estándar',
    shifts: 'Turnos',
    calendar: 'Calendario',
    addShift: 'Agregar turno',
    previousDay: 'Dia anterior',
    noProduction: 'Sin producción',
  },
  tags: {
    csvExample: 'Temp ABC',
    importTags: 'Importar Tags',
    downloadExample: 'Descargar ejemplo',
    advanced: 'Avanzado',
    tagCodeDuplicated: 'Código duplicado en la estructura de importación',
    duplication: 'Duplicación',
    dataInput: 'Entrada de datos',
    sensor: 'Sensor',
    conversion: 'Conversión',
    aggregationType: 'Tipo de agregación',
    calculation: 'Cálculo',
    validateCalculation: 'Validar cálculo',
    validate: 'Validar',
    format: 'Formateo',
    tagType: 'Tipo de tag',
    greaterBetter: 'Más grande mejor',
    smallerBetter: 'Más pequeño mejor',
    decimalPlaces: 'Casas decimales',
    tagFilter: 'Tag de filtro',
    consolidateHour: 'Consolidar la Hora',
    consolidateHourHelp:
      'Consolidación horaria del tag original de acuerdo con su tipo de agregación e guarda el valor consolidado en su tag "_H" puede ser oculto o no.',
    consolidateDay: 'Consolidar el Día',
    consolidateDayHelp:
      'Consolidación del día del tag original de acuerdo con su tipo de agregación e guarda el valor consolidado en su tag "_D" puede ser oculto o no.',
    tagInterpolation: 'Tag de interpolación',
    duplicate: 'Duplicar',
    calculationValidity: 'Validez de los cálculos',
    defaultValidity: 'Validez estándar',
    validity: 'Validez',
    addNewValidity: 'Agregar nueva validez',
    duplicatedValidityError:
      'No es posible ingresar dos validez iguales, la fecha validez ya existe.',
    needToSaveTheTag:
      'Es necesario guardar la tag para que los cambios surtan efecto',
    conversionInterval: 'Intervalo de ejecución de la conversión',
    actionPlanLimitInfo:
      'Límite que, al ser llenado, es utilizado por la herramienta “Generar Plan de Acción” del módulo de Gestión del Plan de Acción para liberar el botón de generación del plan de acción.',
    especificationLimitInfo:
      'Umbral utilizado para la visualización en los componentes del panel, como gráficos, indicadores, cuadrículas y gestión del rendimiento. Cuando el límite del plan de acción no se completa, también se usa para liberar el botón de generación del plan de acción.',
    tagDescription: {
      decimalPlaces: 'Número de decimales',
      upperFilter: 'Filtro superior',
      lowerFilter: 'Filtro inferior',
      lowerEspecificationLimit: 'Límite inferior de especificación ',
      upperEspecificationLimit: 'Límite superior de especificación',
      lowerActionPlanLimit: 'Límite inferior del plan de acción',
      upperActionPlanLimit: 'Límite superior del plan de acción',
      tagType:
        'Tipo de Tag (más pequeño es mejor (<), más grande es mejor (>)),',
      aggregationType: 'Tipo de agregación (AVERAGE, SUM, DIFF, CALC)',
      calcFunction: 'Función de cálculo (si el tipo de agregación es "CALC")',
      dataInput: 'Entrada de datos (sensor, manual, calculation)',
      lowerValidLimit: 'Límite válido inferior',
      upperValidLimit: 'Límite superior válido',
      calculationInput:
        'Cálculo de conversión (si la entrada de datos es "calculation")', // calculation is what save in db and what user needs to put in "dataEntry"
      consolidateHour: 'Consolidar la hora',
      consolidateDay: 'Consolidar el día',
      conversionInterval: 'Intervalo de conversión (mínimo: 1)',
      tagCode: 'Código de la tag',
      tagDescription: 'Descripción de la tag',
    },
    goal: {
      title: 'Objetivo',
      uniqueGoal: 'Objetivo único',
      lowerGoal: 'Objetivo final',
      upperGoal: 'Meta superior',
      proportional: 'Proporcional',
      alreadyExists: 'Ya existe un objetivo para esta tag en este período',
    },
    group: {
      type: 'Tipo del Grupo',
      integration: 'Integración',
      manualCollection: 'Recolección Manual',
      report: 'Informe',
      titleTags: 'Tags',
      availableTags: 'Tags disponibles',
      searchTag: 'Buscar Tags',
      selectedTags: 'Tags seleccionados',
      titleUsers: 'Operadores',
      availableUsers: 'Operadores disponibles',
      searchUser: 'Buscar operadore',
      selectedUsers: 'Operadores seleccionados',
      rules: 'Normas',
      selectedTagNotInCalc: 'La etiqueta seleccionada no está en el cálculo',
      tagsNotInGroup:
        'Hay etiquetas en el cálculo que no pertenecen a este grupo: {tags}',
      unsupportedLogicalOperators:
        'Hay operadores lógicos no admitidos en el cálculo: {operators}',
      syntaxError: 'Error de sintaxis',
      validatedCalculation: 'Cálculo validado',
      validSyntax:
        "Posibles validaciones (>, <, ==, !=). Ej: 'ETIQUETA_REGLA' > ('ETIQUETA1' + 'ETIQUETA2') / 'ETIQUETA3'",
      needAllValidations: 'Todas las reglas necesitan ser validadas',
      validateExpression: 'Validar la expresión',
      tagsWithoutValue:
        'Hay etiquetas en el cálculo sin valor, complete: {tags}, cálculo: {calculation}',
      valueNotValid: 'El valor ingresado no es válido, cálculo: {calculation}',
    },
  },
  integration: {
    log: 'Log',
    onlyErrors: 'Mostrar solo solicitudes con error',
    rerunButton: 'Vuelve a ejecutar las solicitudes',
    executionDate: 'Fecha de ejecución',
    executionTime: 'Tiempo de ejecución',
    payload: 'Carga útil',
    result: 'Resultado',
    triggerType: 'Tipo de disparador',
    eventGroup: 'Grupo de eventos',
    eventType: 'Tipo de evento',
    defaultEvent: 'Evento stándar',
    anyEvent: 'Cualquier evento',
    anyTag: 'Cualquier tag',
    anyMatrix: 'Cualquier matriz',
    invalidCronjob: 'Cronjob inválido',
    invalidJson: 'JSON inválido',
    stepOne: 'Etapa #1',
    step: 'Etapa',
    subStep: 'Subetapa',
    integrationSuccessUpdate: '¡La integración cambió con éxito!',
    default: 'Estándar',
    header: 'Encabezamiento',
    back: 'Vuelve',
    saveChanges: 'guardar cambios',
    dataError:
      'Hubo un problema al consultar los datos de integración. Vuelve a intentarlo.',
    deleteConfirmation: '¿Está seguro de que desea eliminar esta integración?',
    excludeIntegration: 'Eliminar integración',
    editRequests: 'Editar solicitudes',
    addRequests: 'Agregar requisiciones',
    subRequests: 'Sub-requisiciones',
    loop: 'Repetición',
    delay: 'Retrasar',
    get: 'OBTENER',
    post: 'ENVIAR',
    put: 'PONER',
    eventTypeTitle: {
      tagsGroup: 'Grupo de Tags',
      approvalArea: 'Área de aprobación',
      anyFrame: 'Cualquier evento',
      runEvery: 'Ejecutar cada',
      onOpenFrame: 'Al abrir el evento',
      onCloseFrame: 'Al cerrar el evento',
      onCreateEffect: 'Al crear efecto (causa raíz)',
      onCompleteEffect: 'Al completar el efecto (causa raíz)',
      onInsertAction: 'Al insertar una acción (causa raíz)',
      onCompleteAction: 'Al finalizar la acción (causa raíz)',
      onDelayAction: 'Al retrasar la acción (causa raíz)',
      onApproveStatus: 'Al cambiar el estado de aprobación (causa raíz)',
      onSendToApproval: 'Al enviar para aprobación (causa raíz)',
      onOpenProductionOrder: 'Al abrir la orden de producción',
      onCloseProductionOrder: 'Al cerrar la orden de producción',
      onOperationalSecutitySendToApprove:
        'Al cambiar el estado de aprobación (seguridad operacional)',
      onOperationalSecutityApproveStatus:
        'Al cambiar el estado de aprobación (seguridad operacional)',
      onOperationalSecutityExtendSolicitation:
        'Al Solicitar ampliación de impairment (seguridad operacional)',
      onOperationalSecutityExtendSolicitationApprovalStatus:
        'Al cambiar el estado de la solicitud de extensión de impairment (seguridad operacional)',
      onOperationalSecutityExecution: 'Al ejecutar SCI (seguridad operacional)',
      onOperationalSecutityRestoration:
        'Al reiniciar el instrumento (seguridad operacional)',
      onOperationalSecutityRestorationLate:
        'Reincorporación atrasada (seguridad operacional)',
      onOperationalSecutityRoutineTestExecution:
        'Al realizar pruebas de rutina (seguridad operacional)',
      onOperationalSecutityRoutineTestRepeat:
        'Repetición de prueba (seguridad operacional)',
      onOperationalSecutityRoutineTestImpairment:
        'Necesidad de deterioro (seguridad operacional)',
      onOperationalSecutityImpairmentWithoutApprovation:
        'Instrumento desactivado sin aprobación (seguridad operacional)',
      onOperationalSecutityManualOperating:
        'Operando en manual (seguridad operacional)',
      onOperationalSecutityManualInterlock:
        'Enclavamiento manual (seguridad operacional)',
      onOperationalSecutityRoutineTestLate:
        'Retraso de prueba de rutina (seguridad operacional)',
      onManagementOfChangesSendToApprove:
        'Ao enviar para para aprovação (gestión del cambios)',
      onManagementOfChangesApproveStatus:
        'Al cambiar el estado de aprobación (gestión del cambios)',
      onManagementOfChangesExecution: 'Al ejecutar (gestión del cambios)',
      onManagementOfChangesRestoration:
        'Al reiniciar el instrumento (gestión del cambios)',
      onManagementOfChangesRestorationLate:
        'Reincorporación atrasada (gestión del cambios)',
      onIntegrationFailure: 'Falla de integración (integración)',
      onCreateUser: 'Al crear usuario',
      onUpdateUserPassword: 'Al cambiar la contraseña de usuario',
      onClosePRP: 'Al terminar PRP (mantenimiento integrado)',
    },
    title: {
      cronjob: 'Cronjob',
      rootCauseCreateEffect: 'Causa raíz - crear efecto',
      rootCauseEndEffect: 'Causa raíz - efecto final',
      rootCauseInsertAction: 'Causa raíz - insertar acción',
      rootCauseConcludeAction: 'Causa raíz - acción completa',
      rootCauseDelayAction: 'Causa raíz - acción demorada',
      rootCauseSendToApprove: 'Causa raíz - someter para aprobación',
      rootCauseApprovalStatus: 'Causa raíz - cambiar el estado de aprobación',
      startEvent: 'Iniciar evento',
      endEvent: 'Evento final',
      openProductionOrder: 'Orden de producción abierta',
      closeProductionOrder: 'Cerrar orden de producción',
      manualCollect: 'Entrada de datos',
      manualCollectCsv: 'Inserción de datos a través de CSV',
      manualCollectEdit: 'Edición de datos',
      manualCollectDelete: 'Borrado de datos',
      operationalSecutitySendToApprove: 'SCI - Someter para aprobación',
      operationalSecutityApprovalStatus:
        'SCI - Cambiar el estado de aprobación',
      operationalSecutityExtendSolicitation:
        'SCI - Solicitud de ampliación de impairment',
      operationalSecutityExtendSolicitationApprovalStatus:
        'SCI - Cambiar el estado de solicitud de ampliación de impairment',
      operationalSecutityExecution: 'SCI - Ejecución',
      operationalSecutityRestoration: 'SCI - Restauracion',
      operationalSecutityRestorationLate: 'SCI - Reincorporación atrasada',
      operationalSecutityRoutineTestExecution: 'SCI - Prueba de rutina',
      operationalSecutityRoutineTestRepeat: 'SCI - Repetición de prueba',
      operationalSecutityRoutineTestImpairment:
        'SCI - Necesidad de impairment ',
      operationalSecutityImpairmentWithoutApprovation:
        'SCI - Instrumento desactivado sin aprobación',
      operationalSecutityManualOperating: 'SCI - Operando en manual',
      operationalSecutityManualInterlock: 'SCI - Enclavamiento manual',
      operationalSecutityRoutineTestLate: 'SCI - Retraso de prueba de rutina',
      managementOfChangesSendToApprove: 'MOC - Someter para aprobación',
      managementOfChangesApprovalStatus:
        'MOC - Cambiar el estado de aprobación',
      managementOfChangesExecution: 'MOC - Ejecución',
      managementOfChangesRestoration: 'MOC - Restauracion',
      managementOfChangesRestorationLate: 'MOC - Reincorporación atrasada',
      integration: 'Integración',
      integrationFailure: 'Integración - Falla de integración',
      createUser: 'Creación de usuario',
      updatePassword: 'Actualización de contraseña de usuario',
    },
    variables: {
      effectOne: 'Efecto T # 1',
      effectOneCommentary: 'Este efecto se completó el 2010-02-25',
      actionOne: 'Acción A # 1',
      actionOneWhy: 'Debido a la acción A # 1',
      actionOneHow: 'A partir de la Acción A # 1',
      actionOneWhat: '¿Qué pasa con la acción A # 1',
      actionOneCommentary: 'Comente sobre la acción A # 1',
      eventTitle: 'Título del evento',
      eventCode: 'ID de evento',
      productionOrderCode: 'codigo#123',
      productionOrderExternalCode: 'codigo-externo#123',
      productionOrderItemCode: 'Código del objeto',
      productionOrderExternalItemCode: 'elemento de código externo',
      tagCode: 'TAG_CODIGO',
      sciInstrumentDesc: 'Descripción del instrumento',
      mocDesc: 'Nombre de cambio',
      genericText:
        'Lorem ipsum dolor sit amet. Et eligendi pariatur sed deserunt quae eum nostrum galisum et eveniet nulla aut omnis voluptas.',
      integrationTitle: 'Título de integración',
      eventType: 'Tipo de evento',
      step: 'Paso #1',
      endpoint: 'http://localhost:9000/endpoint',
      payload: "{'name': 'joao', senha: 'senha123'}",
      response: "{'error':{'code': 400}}",
    },
    parameters: {
      errorCreateNew: 'Error al crear un nuevo parámetro de integración',
    },
    laboratory: {
      integrationType: 'Tipo de integración',
      pimsLab: 'Integración de laboratorio',
      pimsWrite: 'Cierre del reporte - escritura',
      pimsRead: 'Cierre del reporte - lectura',
      originVariable: 'Variable fuente',
      destinyVariable: 'Variable objetivo',
      hourVariable: 'Variable de hora',
      ruleCollect: 'Regla de colección',
      ruleZero: 'Regla 0',
      ruleZeroDescription: 'Hora en la línea superior de la pantalla PIMS',
      ruleOne: 'Regla 1',
      ruleOneDescription:
        'Escribe la hora en otra variable PIMS sin la subfuente (defina esta variable en la Tag)',
      ruleTwo: 'Regla 2',
      ruleTwoDescription: '3 tipeos por día (1 por turno)',
      ruleThree: 'Regla 3',
      ruleThreeDescription: '6 tipeos por día (2 por turno)',
      ruleFour: 'Regla 4',
      ruleFourDescription:
        'Primera hora del día incrementando cada 1 hora (24 tipeos en el día)',
      ruleFive: 'Regla 5',
      ruleFiveDescription:
        'Devuelve el promedio calculado en la última columna de PIMS',
      ruleSix: 'Regla 6',
      ruleSixDescription:
        'Convierte un texto digitado en PIMS a un valor numérico del registro realizado en variables globales',
      typeNotFound: 'Tipo no encontrado',
      type: 'Tipo',
    },
  },
  integrationSystem: {
    production: 'Producción y proceso',
    enableApprove: 'Habilitar aprovação',
    enableInsertAppointment: 'Habilitar la inserción de anotaciones',
    tagsGroup: 'Grupo de Tags',
    showDayAggregation: 'Visualización de agregación por día',
    showShiftAggregation: 'Visualización de agregación por turno',
  },
  manualCollect: {
    appointmentsQuantity: 'Numero de notas',
    startHour: 'Hora de inicio',
    type: 'Tipo',
    createNewItem: 'Crear un artículo nuevo',
    tagGroup: 'Grupo de Tags',
    onlyShowManualInput: 'Solo las Tags de entrada manual se mostrarán',
    notShowButton: 'El botón agregar nuevos tiempos no se mostrará',
    showEndTime: 'Mostrar la hora de finalización',
    alreadyAssociated: 'Grupo de Tags ya asociado a una colección',
    group: {
      permissions: 'Permisos',
      userPermissions: 'Permiso para usuarios',
    },
  },
  oee: {
    title: 'OEE',
    available: 'Disponibilidad',
    quality: 'Calidad',
    performance: 'Actuación',
    dashboard: 'Dashboard',
    dashboardNotFound: 'Dashboard no encontrado',
    dailyValues: 'Valores diarios',
    resume: 'resumen',
    back: 'Vuelve',
    next: 'Próximo',
    instructions: 'Instrucciones',
    availableFirstParagraph:
      'Si la automatización ya ha calculado el tiempo de inactividad, seleccione la Tag que se recopilará. De lo contrario, seleccione o registre un nuevo evento',
    availableSecondParagraph:
      'Nota: Si se crea un nuevo evento, es necesario configurar o señalar el motivo de la parada manualmente',
    availableTag: 'Tag de disponibilidad',
    tagDescription: 'Descripción del Tag',
    tagCode: 'Código del Tag',
    availableOptionOne: 'Opción 1: mediante un Tag registrado',
    availableOptionTwo: 'Opción 2: a través de un nuevo Tag',
    availableOptionThree: 'Opción 3 - vía evento',
    stopsEvent: 'Evento de paradas',
    createNewFrame: 'Crear nuevo evento',
    dashboardParagraph:
      'Defina cómo se mostrará la OEE dentro del dashboard en el menú y el título de la pestaña. También es posible agregar un icono para identificarlos mejor',
    viewTitle: 'Ver título',
    tabTitle: 'Título tab',
    descriptionParagraph:
      'Introduzca un título de descripción para la OEE que se creará. Este título se mostrará en la tabla de OEE creados',
    oeeTitle: 'Título de la OEE',
    oeeStepParagraph:
      'Introduzca un título de descripción para la OEE que se creará. Este título se mostrará en la tabla de OEE creados',
    tagCalculation: 'Cálculo de Tags',
    performanceParagraph:
      'Señalar el desempeño del equipo, definiendo la cantidad de piezas producidas por ciclo y definir si el tiempo de ciclo se obtendrá a través de un Tag o si será un tiempo de ciclo fijo',
    tagQuality: 'Tag de calidad',
    tagPerformance: 'Tag de actuación',
    ciclePieces: 'Piezas por ciclo',
    cicleTime: 'Tiempo de ciclo - TAG',
    performanceOptionThree: 'Opción 3: por tiempo fijo',
    fixedCicleTime: 'Tiempo de ciclo - fijo',
    inSeconds: 'En segundos',
    oeeAvailable: 'OEE - Disponibilidad',
    oeePerformance: 'OEE - Rendimiento',
    oeeQuality: 'OEE - Calidad',
    oeeGoodProduction: 'OEE - Buena producción',
    oeeBadProduction: 'OEE - Producción defectuosa (Descarte)',
    oeeTotalProduction: 'OEE - Producción total',
    qualityParagraph:
      'Seleccione los Tags que correspondan a cada indicador. Para los campos que se dejan en blanco, el sistema completará un Tag generado automáticamente. Seleccione el Tag que debe ser calculado por el sistema, los que no estén seleccionadas serán considerados como valores a recolectar',
    goodProduction: 'Buena produccion',
    calculation: 'Cálculo',
    manualCollectScreen: 'Pantalla de recogida manual',
    createNewItem: 'Crear un elemento nuevo',
    badProduction: 'Producción defectuosa',
    totalProduction: 'Producción total',
    resumeFirstParagraph:
      'Tag de información que representará la calidad de la producción en el dashboard:',
    resumeSecondParagraph:
      'Tag de información que representará el rendimiento del equipo en el dashboard:',
    resumeThirdParagraph:
      'Información de Tag que representará la disponibilidad del equipo en el dashboard:',
    resumeFourthParagraph:
      'Información de la Tag que representará el resultado de la producción, presentando el producto de la multiplicación de los tres Tags presentados anteriormente.',
    resumeFifthParagraph:
      'Se creará un nuevo dashboard en el menú “Dashboard”, donde será posible visualizar el resultado de los cálculos realizados por el sistema y presentado a través de un cuadro tipo velocímetro. Los valores resultantes se presentarán a través de los Tags descritos en este resumen.',
    type: 'Tipo',
    selected: 'Seleccionado',
    report: {
      externalCloseStatus: 'Estado de cierre externo',
      internalCloseStatus: 'Estado de cierre interno',
    },
  },
  token: {
    title: 'Simbólico',
    successCreate: 'Token creado correctamente',
    showOnce:
      'Este token solo se mostrará una vez, guárdelo en un lugar seguro',
  },
  integratedMaintenance: {
    accomplished: 'Logrado',
    programmed: 'Programado',
    weekAndMonth: 'Para semanas se considerarán 7 días, y para meses 30 días',
    day: 'día(s)',
    week: 'semana(s)',
    month: 'mes(es)',
    year: 'año(s)',
    osGenerated: 'O.S. generada:',
    serviceOrderType: 'Tipo de órdenes de servicio',
    observationNeeded:
      'En caso de desaprobación, la observación es obligatoria',
    approveReprove: '¿Estás seguro de que quieres aprobar/reprobar este S.O.?',
    initialStatus: 'Inicial',
    inProgressStatus: 'En proceso',
    waitingApprovalStatus: 'Esperando aprobación',
    finishedStatus: 'Finalizado',
    approvedStatus: 'Aprobado',
    reprovedStatus: 'Sesaprobado',
    verification: 'Verificación',
    blockServiceOrder: 'Bloquear O.S.',
    initialServiceOrderStatus: 'Estado inicial del O.S.',
    title: 'Mantenimiento Integrado',
    quantitativeVerification: 'Verificación cuantitativa',
    qualitativeVerification: 'Verificación cualitativa',
    lubricationGrease: 'Lubricación/grasa - cuantitativo',
    lubricationExecution: 'Lubrificación/grasa - ejecución',
    procedureExecution: 'Ejecución del procedimiento',
    materialType: 'Tipo de material',
    piece: 'Pieza',
    lubricant: 'Lubricante',
    externalService: 'Servicio externo',
    lastPurchasePrice: 'Costo unitario',
    quantityStock: 'Cantidad de inventario',
    quantityReserved: 'Cantidad reservada',
    quantityReservedService: 'Cantidad reservada servicio',
    price: 'Precio',
    serviceType: 'Tipo de servicio',
    preventiveModality: 'Modalidad preventiva',
    routineItems: 'Artículos de rutina',
    elementTag: 'Elemento / Tag',
    service: 'Servicio',
    setFrequencyOnRoute: 'Establecer frecuencia y tiempo esperado en la ruta',
    frequency: 'Frecuencia',
    expectedTime: 'Tiempo previsto',
    expectedTimeMin: 'Tiempo previsto (min)',
    element: 'Elemento',
    elements: 'Elementos',
    material: 'Material',
    maxLimit: 'Límite máximo',
    minLimit: 'Límite mínimo',
    okNotOk: 'Conforme/no conforme',
    executedNotExecuted: 'Procedimiento realizado/procedimiento no realizado',
    prpCreated: 'PRP creado con éxito!',
    selectOperator: 'Seleccione el operador responsable',
    selectShift: 'Seleccionar turno',
    selectOrder: 'Seleccionar orden',
    createPrp: 'Crear PRP',
    onClosePRP: 'Al final del PRP',
    seeCompleteOs: 'Ver O.S. completar',
    executed: 'Ejecutado',
    notExecuted: 'Sin ejecutar',
    ok: 'Conforme',
    notOk: 'No conforme',
    to: 'a la',
    os: 'O.S.',
    requestDate: 'Fecha de solicitud',
    conclusionDate: 'Fecha de finalizacion',
    actualStartDate: 'Fecha de inicio real',
    actualEndDate: 'Fecha de finalizacion real',
    status: 'Estado',
    noOsToShow: 'Sin O.S. para mostrar',
    prp: 'Preventivo y predictivo',
    execution: 'Ejecución',
    obtained: 'Adquirido',
    osByPrp: 'O.S. generado por PRP',
    mcu: 'Mantenimiento correctivo urgente',
    mcp: 'Mantenimiento correctivo programado',
    nm: 'Nota de mantenimiento',
    prpUc: 'PRP',
    mcuUc: 'MCU',
    mcpUc: 'MCP',
    planned: 'Planificado',
    performed: 'Llevado a cabo',
    hoursUsed: 'Horas gastadas',
    inspectionRoutesHoursUsed: 'Rutas de inspección x horas gastadas',
    plannedExecuted: 'Planeado x logrado',
    requester: 'Solicitante',
    responsable: 'Responsable',
    route: 'Ruta',
    responsableOperator: 'Responsable',
    approvedBy: 'Aprobado por',
    disapprovedBy: 'Rechazado por',
    approvedAt: 'Aprobado el',
    disapprovedAt: 'Rechazado en',
    approved: 'Aprobado',
    disapproved: 'Rechazado',
    maintenanceActivity: 'Actividad de mantenimiento',
    priority: 'Prioridad',
    searchOperator: 'Buscar por operador',
    serviceOrder: 'Orden de servicio',
    osType: 'Tipo OS',
    emission: 'Emisión',
    criticality: 'Criticidad',
    materialsUsed: 'Materiales usados',
    materialsForeseen: 'Materiales esperados',
    materialForeseen: 'Material esperado',
    quantityUsed: 'Cantidad usada',
    quantityForeseen: 'Cantidad esperada',
    operator: 'Operador',
    urgent: 'Urgente',
    moderate: 'Moderar',
    itemsRoute: 'Elementos de ruta',
    obtainedInInspection: 'Obtenido en la inspección',
    stopped: 'Detenido',
    stoppedEquipment: 'Equipo detenido',
    observation: 'Nota',
    osItems: 'Servicios de la O.S.',
    executionDate: 'Fecha de ejecución',
    performer: 'Ejecutante',
    serviceOrderFinished: 'Ordem de servicio completada',
    serviceOrderCanceled: 'Ordem de servicio cancelada',
    serviceOrderOpen: 'Ordem de serviço abierta',
    wasFinished: 'Esta orden de servicio ha sido completada',
    wasCanceled: 'Esta orden de servicio ha sido cancelada',
    clickToReopen: 'Haga clic aquí para volver a abrir',
    attachmentSuccess: 'Archivo adjunto correctamente',
    attachmentDeleteError:
      'Error al intentar eliminar el archivo adjunto de la orden de servicio',
    finishServiceOrder: 'Orden de servicio completa',
    cancelServiceOrder: 'Orden de servicio cancelada',
    clickToAddAttachment: 'Haga clic para agregar adjunto',
    situation: 'Situación',
    cost: 'Costo',
    serviceCost: 'Costo de servicio',
    generateErpRequisition: 'Generar requisición ERP',
    typeCurrentStatus: 'Ingrese el estado actual',
    typeDetails: 'Ingrese las notas',
    generateOs: 'Generar O.S.',
    generateNote: 'Generar nota',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    hoursPlanned: 'Horas previstas',
    plannedStartTime: 'Fecha de inicio planificada',
    realStartTime: 'Fecha de inicio real',
    plannedEndTime: 'Fecha de finalización planificada',
    realEndTime: 'Fecha de finalización real',
    osCreationDate: 'Fecha de creación de la O.S.',
    serviceCreationDate: 'Fecha de creación de lo servicio',
    addService: 'Agregar servicio',
    labor: 'Mano de obra',
    center: 'Centrar',
    deposit: 'Depositar',
    reservationNumber: 'Nº reserva',
    attendanceNumber: 'Nº asistencia',
    estimatedCost: 'Costo estimado',
    realCost: 'Costo real',
    lastAppointment: 'Última nota',
    serviceDescription: 'Descripción del servicio',
    hoursAppointed: 'Horas designadas',
    appointLabor: 'Punto de mano de obra',
    lastAppointmentInformed: 'Última nota ya informada',
    isActive: 'Activo',
    expectedQuantity: 'Cantidad Esperada',
    amountUsed: 'Cantidad Usada',
    serviceCode: 'Código de Servicio',
    resourceGroup: 'grupo de recursos',
    numberOfOperators: 'Número de Operadores',
    realStartAndEnd: 'Fecha Real de Inicio / Finalización',
    elementAndEquipment: 'Elemento / Equipo',
    futureHoursNotAllowed: 'No se permite ingresar horas futuras',
    betweenDaysNotAllowed: 'No se permite anotar entre diferentes días',
    betweenServiceOrdersNotAllowed:
      'No se permite anotar entre diferentes servicios, O.S.: {serviceOrder}, servicio: {service}',
    betweenServiceOrdersNotAllowedWithoutService:
      'No se permite anotar entre diferentes servicios, O.S.: {serviceOrder}',
    startTimeEqualEndTime:
      'No está permitido anotar la hora de inicio igual a la hora de finalización',
    maxTimeUninterruptedNotAllowed:
      'No se permiten entradas mayores que eso {time} ininterrumpido',
    maxTimeDayNotAllowed:
      'No se permiten entradas mayores que eso {time} diario',
    maxTimeDayNotAllowedServiceOrder:
      'No se permiten entradas mayores que eso {time} diario, O.S.: {serviceOrder}, servicio: {service}',
    maxTimeDayNotAllowedServiceOrderWithoutService:
      'No se permiten entradas mayores que eso {time} diario, O.S.: {serviceOrder}',
    minBreakTimeDayNotAllowed:
      'No se permiten entradas menos que eso {time} entre días',
    historic: 'Movimiento de equipos',
    sourceElement: 'Elemento fuente',
    destinationElement: 'Elemento de destino',
    movementDate: 'Fecha de mudanza',
    moveEquipment: 'Mover equipo',
    substituteEquipment: 'Equipo sustituto',
    associatedEquipment:
      'Este equipo tiene asociados servicios de mantenimiento preventivo, informar equipo de reemplazo',
    sequenceAlreadyExists: 'Secuencia ya existente',
    sourceServiceOrder: 'Código del O.S. fuente',
    standardMap: 'Mapa estándar',
    satteliteMap: 'Mapa satelital',
    rules: {
      maxTimeUninterrupted: 'Tiempo máximo ininterrumpido',
      maxTimeDay: 'Tiempo máximo diario',
      minBreakTimeDay: 'Descanso mínimo entre días',
      appointFutureHours: 'Consultar cita de horas futuras',
      appointBetweenDays: 'Consultar cita entre diferentes días',
      appointBetweenServiceOrders: 'Consultar cita entre diferentes servicios',
      automaticRegistrationApp: 'Registro laboral automático vía aplicación',
    },
    integrationStatus: {
      label: 'Estado de integración',
      waiting: 'Esperando',
      success: 'Éxito',
      error: 'Error',
    },
    report: {
      numberOfOrdersPerDay: 'Número de órdenes programados X realizados',
      numberOfOrdersPerActivityType: 'Número de órdenes X tipo de orden',
      hourPerElementPerAcitivityType:
        'Asignación de tiempo X grupo de recursos',
      numberOfOrdersPerExecutionStatus:
        'Número de órdenes X estado de ejecución',
      numberPerElementPerStatusByDay: 'Status X período X grupo de recursos',
      percentOfOrdersPerUserGroups: 'Porcentaje de pedidos X grupo de recursos',
      numberOfOrdersPerUserGroups: 'Número de pedidos X grupo de recursos',
      costForeseenPerOsType:
        'M.O esperada X materiales esperados X tipo de orden',
      costForeseenPerElement: 'M.O esperada X materiales esperados X elemento',
      costPerOsType: 'M.O usada  X materiales usados X tipo de orden',
      costPerElement: 'M.O usada X materiales usados X elemento',
      numberOfOrderPerElement: 'Número de órdenes X elemento',
      numberOfOrdersPerElementPerActivityType:
        'Número de órdenes X tipo de orden X elemento',
      selectReport: 'Seleccione un informe para generar el gráfico',
      numberOfHours: 'Número de horas',
      numberOfOrders: 'Número de órdenes',
      withoutStatus: 'Sin estatus',
      laborForeseen: 'M.O esperada',
      materialsForeseen: 'Materiales esperados',
      laborUsed: 'M.O usada',
      materialsUsed: 'Materiales usados',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
      january: 'Enero',
      february: 'February',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
      programmedAccomplished:
        'Programado: {total} - Logrado: {completed} - Porcentage: {percentage}%',
      total: 'Total: {total}',
      programmed: 'Total',
      accomplished: 'Logrado',
    },
  },
  pdfTemplate: {
    headerImage: 'Imagem do encabezamiento',
    footerImage: 'Imagem do pie de página',
    templates: 'Plantillas',
  },
  dataExport: {
    horizontalTag: 'Horizontal - Tag',
    horizontalDate: 'Horizontal - Fecha',
  },
  barcodeLabel: {
    content: 'Contenido del código',
    contentHelp: 'Usa el lector para establecer un contenido predefinido',
    module: 'Módulo',
    QRCodeDynamic: 'Código QR dinámico',
    addStep: 'Agregar paso',
    payload_id: 'Item',
    payload_value: 'Valor',
  },
}

export default localeValues
